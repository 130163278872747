<template>
  <div style="width: 95%;margin: 10px auto">
    <v-toolbar flat>
      <v-toolbar-title>{{ $t("users") }}</v-toolbar-title>
      <v-divider class="mx-4" inset vertical></v-divider>
      <v-toolbar-title>
        {{ translate(restaurantName) }}: {{ $route.params.id }}
      </v-toolbar-title>
      <v-divider class="mx-4" inset vertical></v-divider>
<!--            <import-excel :dialog="dialogImport" @close="dialogImport = false"></import-excel>-->
<!--            <v-btn v-if="$store.state.user.type != 'dostava' && $store.state.user.type != 'admin_delivery'" outlined rounded small color="#fb6d3b" @click="dialogImport = true">Import Excel</v-btn>-->
      <v-spacer></v-spacer>
      <v-text-field
        class="pr-4 pb-2"
        v-model="search"
        append-icon="mdi-magnify"
        :label="$t('search')"
        single-line
        hide-details
        dense
      ></v-text-field>
<!--      @input="obrok"-->
      <v-autocomplete class="pt-2 mr-2" v-if="children && children.length > 0 && parentCompanies.length && parentCompanies.find(company => company.parent_id == 0).id == idProp"
          v-model="companyChildren"
          :items="parentCompanies"
          dense
          multiple
          :item-text="getItemText"
          item-value="id"
          @input="addUsersToParentCompany()"
          :label="$t('select_company')"
          clear
          chips
          small-chips
      >
        <v-btn
            color="primary"
            slot="prepend-item"
            @click="addAllUsersToParent()"
            small
            class="mx-4"
        >
          {{$t("display_all")}}
        </v-btn>
      </v-autocomplete>
      <v-spacer></v-spacer>
      <v-btn v-if="$route.name == 'restoran-id-korisnici'"
             outlined style="border-radius: 9px"
             color="orange darken-4"
             small
             class="mr-2"
             @click="$router.push({ name: 'QRlist', params: { res: idProp } })"
      >
        <v-icon class="mr-2">mdi-checkerboard</v-icon
        >{{ $t("qr") }}
      </v-btn>
      <v-btn v-else style="border-radius: 9px"
        outlined
        color="orange darken-4"
        small
        class="mr-2"
        @click="$router.push({ name: 'QRlist', params: { idProp, children: children, parentCompanies:parentCompanies } })"
      >
        <v-icon class="mr-2">mdi-checkerboard</v-icon
        >{{ $t("qr") }}
      </v-btn>

      <v-dialog v-model="dialog" max-width="600px" scrollable>
        <template v-slot:activator="{ on, attrs }">
          <v-btn :title="$t('print')" style="border-radius: 9px" outlined color="orange darken-4" small class="ml-2" @click="print('print')">
            <v-icon class="">mdi-printer</v-icon>
            <!--              {{ $t("print") }}-->
          </v-btn>
          <v-btn title="Vise korisnika odjednom" style="border-radius: 9px" outlined color="orange darken-4" class="ml-2" small @click.prevent="addMultipleUsers">
            <v-icon>mdi mdi-account-multiple-plus</v-icon>
          </v-btn>
          <v-btn v-if="$store.state.user.type == 'admin'" title="Pretraga korisnika po ID" style="border-radius: 9px" outlined color="orange darken-4" class="ml-2" small @click.prevent="openModalSearchUserById()">
            <v-icon>mdi mdi-store-search-outline</v-icon>
          </v-btn>
          <v-btn outlined color="orange darken-4" :style="'border-radius: 8px;border: 1px solid ' + backGround + ';background: none;color: ' + backGround + ';box-shadow: none;text-transform: capitalize'" dark v-bind="attrs" v-on="on" small>
            <v-icon class="mr-2">mdi mdi-account-plus</v-icon>{{ $t("add_worker") }}
          </v-btn>
        </template>

        <v-card>
          <v-card-title class="primary" style="display: flex;justify-content: space-between;align-content: center">
            <span class="headline white--text"
              >{{ translate(formTitle) }} - {{ editedItem.id }}
            </span>
            <v-btn color="white" text @click="close">
              <v-icon class="mr-2">mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text>

            <v-container>
              <v-row class="mt-3">
                <v-switch
                  v-model="editedItem.state"
                  inset
                  :label="$t('active')"
                  :false-value="0"
                  :true-value="1"
                  dense
                ></v-switch>
                <v-spacer></v-spacer>
                <v-switch
                  v-if="$route.params.parent_id || editedItem.children && editedItem.children.length > 0"
                  v-model="editedItem.is_transferable"
                  inset
                  :label="$t('change_location')"
                  :false-value="0"
                  :true-value="1"
                  dense
                ></v-switch>
                <v-switch
                    v-if="editedItem.all_cooperation"
                    v-model="editedItem.can_change_restoran_company"
                    inset
                    :label="$t('can_change_restoran_company')"
                    :false-value="0"
                    :true-value="1"
                    dense
                ></v-switch>
<!--                      <v-switch-->
<!--                          v-if="editedItem.company && editedItem.company.no_time_limit"-->
<!--                          v-model="editedItem.no_time_limit_user"-->
<!--                          inset-->
<!--                          :label="$t('no_time_limit')"-->
<!--                          :false-value="0"-->
<!--                          :true-value="1"-->
<!--                          dense-->
<!--                      ></v-switch>-->
                <v-select v-if="editedIndex != -1"
                    v-model="editedItem.company"
                    :items="parentCompanies || []"
                    :label="translate('Тренутна фирма')"
                    item-text="name"
                    item-id="id"
                    @change="openChangeCompany()"
                    return-object
                ></v-select>
                <p>{{ translate('Промена пословнице за наручене оброке важи  од следећег дана') }}</p>
                <v-row style="margin-top: 40px">
                  <v-col v-if="editedItem.image_path" cols="5">
                    <v-img style="max-width: 140px; margin: 0 auto" :src="conf.apiUrlBase + '/gallery/' + editedItem.image_path"></v-img>
                  </v-col>
                  <v-col cols="5">
                    <input type="file" id="upload" @change="upload" />
                    <label for="upload">{{ $t("add_new_image") }}</label>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      v-model="editedItem.name"
                      :rules="[$t('required_field')]"
                      :label="$t('name')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model="editedItem.lastname"
                      :rules="[$t('required_field')]"
                      :label="$t('last_name')"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" :title="translate('Предлог ime.prezime@domen_firme')">
                    <v-text-field
                      v-model="editedItem.email"
                      :rules="[$t('required_field')]"
                      :label="$t('email_or_username')"
                    ></v-text-field>
                    <p style="font-size: 15px;color: #c20606;border: 1px solid darkred" v-if="errorMessageEmail">{{ errorMessageEmail }}</p>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                        v-model="editedItem.private_email"
                        :label="$t('private_email')"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" v-if="$store.state.company&&$store.state.company.children.length>0&&formTitle=='Измјена корисника'">
                    <v-select
                      v-if="$store.state.company"
                      :items="$store.state.company.children"
                      :label="$t('office')"
                      item-text="name"
                      item-value="id"
                      :rules="[$t('required_field')]"
                      v-model="editedItem.company_id"
                    ></v-select>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                        v-model="editedItem.password"
                        :rules="[$t('required_field')]"
                        :label="$t('password')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-select
                      :disabled="editedItem.type === 'admin'"
                      :items="
                      $route.name === 'restoran-id-korisnici'
                        ? [{text: $t('restaurant'),val:'restaurant'},
                         {text:translate('QR скенер / подела наруџби'),val:'payment'},
                         {text:translate('Извештаји / наруџбе радник'),val:'report'},
                         {text:translate('Извештаји / наруџбе безуговорца'),val:'report_guest'},
                         {text:$t('stock'),val:'warehouse'},
                         {text:$t('table'),val:'table'},
                         {text:$t('worker_more_restaurants'),val:'worker_more_restaurants'},
                         // {text:$t('delivery1'),val:'delivery'}
                        ]
                        : $route.params.delivery && $route.params.delivery === 'dostava' ?
                         [ {text: translate('Достављач'), val:'dostava'},
                         { text: translate('Админ - Достава'), val:'admin_delivery'},
                        ]
                        : [{text: $t('worker'), val:'customer'},
                         {text:translate('Компанија - администратор'),   val:'company'},
                         {text:translate('Пријем / Испорука оброка'), val:'recipient'},
                         {text:translate('Гост'), val:'guest'},
                         {text:$t('table'),val:'table'},
                         {text:$t('worker_more_restaurants'),val:'worker_more_restaurants'},
                        ]
                      "
                        :label="$t('user_type')"
                        :item-text="item => translate(item.text)"
                        item-value="val"
                        :rules="[$t('required_field')]"
                        v-model="editedItem.type"
                    ></v-select>
                  </v-col>
                </v-row>
                <template v-if="editedItem.type === 'recipient'">
                  <v-col cols="6">
                    <v-switch
                        v-model="editedItem.is_contract"
                        inset
                        :label="$t('is_contract')"
                        :false-value="0"
                        :true-value="1"
                        dense
                    ></v-switch>
                  </v-col>
                  <v-col cols="6">
                    <v-switch
                        v-model="editedItem.is_non_contract"
                        inset
                        :label="$t('is_non_contract')"
                        :false-value="0"
                        :true-value="1"
                        dense
                    ></v-switch>
                  </v-col>
                </template>
                <v-col cols="12">
                  <v-card flat v-if="editedItem.type == 'recipient'">
<!--                          <v-card-text>-->
                      <v-autocomplete
                          v-model="searchs"
                          :items="companies[0].children.filter((i) => i.name)"
                          dense
                          filled
                          multiple
                          :label="$t('choose_office')"
                          item-text="name"
                          item-value="id"
                          clear
                          chips
                          small-chips
                      >
                        <v-btn
                            color="primary"
                            slot="prepend-item"
                            @click="searchs=[]"
                            small
                            class="mx-4"
                        >
                          {{$t("display_all")}}
                        </v-btn>
                      </v-autocomplete>
                      <div >
                        <template
                          v-for="(company) in searchs && searchs.length > 0
                            ? companies[0].children.filter((i) => searchs.includes(i.name))
                            : companies[0].children"
                             dense
                        >
                        </template>
                      </div>
<!--                          </v-card-text>-->
                  </v-card>
                </v-col>
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      v-model="editedItem.address"
                      :label="$t('address')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                  <v-text-field
                    v-model="editedItem.phone"
                    :label="$t('phone')"
                  ></v-text-field>
                </v-col>
                </v-row>
                <!-- <v-col cols="12">
                  <v-text-field
                    v-model="editedItem.code"
                    :label="translate('Екстерни код')"
                  ></v-text-field>
                </v-col> -->
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      v-model="editedItem.workPos"
                      :label="$t('work_place')"
                    ></v-text-field>
                  </v-col>
<!--                      <v-col cols="12">-->
<!--                        <v-select-->
<!--                          v-model="editedItem.notification"-->
<!--                          :items="items"-->
<!--                          :label="$t('select_notification')"-->
<!--                        ></v-select>-->
<!--                      </v-col>-->
                  <v-col cols="6">
                  <v-text-field
                      v-model="editedItem.external_code"
                      :label="translate('Ekstrni ID')"
                  ></v-text-field>
                </v-col>
                </v-row>
                <v-col cols="12" class="mb-0 pb-0">
                  <p class="text-left">{{ translate('Датум креирања налога и едитовања') }}:</p>
                </v-col>
                <v-col cols="6" :title="translate('Датум регистрације')">
                    <v-icon class="mr-2">mdi-calendar</v-icon>
                    {{moment(editedItem.created_at).format("DD.MM.YYYY. h:mm:ss")}}
                </v-col>
                <v-col cols="6" :title="translate('Датум измене')">
                  <v-icon class="mr-2">mdi-calendar</v-icon>
                  {{moment(editedItem.updated_at).format("DD.MM.YYYY. h:mm:ss")}}
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="close">
              {{ $t("cancel") }}
            </v-btn>
            <v-btn color="primary" dark @click="save">
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="headline text-center w-100">
            <p style="width: 100%" class="text-center">{{$t("delete_confirmation") }}: {{ editedItem.name}}</p>
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDelete">{{
              $t("cancel")
            }}</v-btn>
            <v-btn
              color="primary"
              text
              @click="deleteItemConfirm"
              >{{ translate('Обриши') }}</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-toolbar>
    <div>
      <!-- desserts-->
      <v-data-table
        :headers="headers"
        :items="filteredItems"
        sort-by="name"
        class="elevation-1 custom-data-table"
        dense
        mobile-breakpoint="0"
        id='print'
      >
        <template v-for="header in headers"
          v-slot:[`header.${header.value}`]="{ header }"
        >
          {{ $t(header.text) }}
        </template>
        <template v-for="values in headers"
          v-slot:[`item.${values.value}`]="{ item }"
        >
          {{ translate(item[values.value]) }}
        </template>
        <template v-slot:item.type="{ item }">
          {{
            item.type == "customer" ? $t("worker") : item.type == "company" ? $t("firm") : item.type == "restaurant" ? $t("restaurant") : item.type == "recipient" ? $t("delivery") : item.type == "guest" ? $t("guest") : item.type == "report" ? $t("report") : item.type == "report_guest" ? $t("guest_report") : item.type == "warehouse" ? $t("stock") : item.type == "dostava" ? $t("delivery1") : item.type == "admin_delivery" ? $t("admin_delivery") : item.type == "table" ? $t("table") : item.type == "worker_more_restaurants" ? $t("worker_more_restaurants") : $t("delivery")
          }}
        </template>
        <template v-slot:item.state="{ item }">
<!--          {{ item.state ? translate("Активан") : translate("Неактиван") }}-->
          <span style="color: #1e7e34" v-if="item.state">{{ $t("active") }}
<!--            <v-icon small style="color: #1e7e34" class="px-2">mdi-check</v-icon>-->
          </span>
          <span style="color: #c20606" v-if="! item.state">{{ $t("inactive") }}
<!--            <v-icon small style="color: #c20606;font-size: 10px" class="px-2">mdi-circle</v-icon>-->
          </span>
        </template>
<!--        <template v-if="$store.state.user.type == 'admin'" v-slot:item.restoran="{ item, index }">-->
<!--          <v-checkbox :title="$t('add_sub_restaurant')" v-model="add_restoran[index]" @change="setAddRestoran(item, index)"></v-checkbox>-->
<!--        </template>-->
        <template v-if="$store.state.user.type == 'admin'" v-slot:item.menu="{ item, index }">
          <v-icon :title="$t('edit')" small color="primary" class="mr-2" @click="userCanSeeMenu(item, index)"> mdi mdi-microsoft-xbox-controller-menu </v-icon>
        </template>
<!--        <template v-if="$store.state.user.type == 'admin'" v-slot:item.podesavanja="{ item, index }">-->
<!--          <v-checkbox :title="$t('user_settings')" v-model="item.pivot.settings" @change="setPodesavanja(item, index)"></v-checkbox>-->
<!--        </template>-->

        <template v-slot:item.can_report="{ item, index }">
          <span style="display: flex;justify-content: center">
            <v-checkbox v-if="item.type == 'company'" :title="translate('Управљање налозима радника и преглед извештаја')" v-model="item.can_report" @change="setCanReport(item, index)"></v-checkbox>
          </span>
        </template>
        <template v-slot:item.credit="{ item, index }">
         {{ item.credit[0] ? item.credit[0].duguje : '' }}
        </template>

        <template v-slot:item.time_limit="{ item, index }" style="display: flex;justify-content: center">
          <span v-if="item.company && item.company.no_time_limit" style="display: flex;justify-content: center">
            <v-checkbox v-if="item.company.no_time_limit" :title="translate('Користи се за фирме код којих је подешено да се за њихове раднике може наручити мимо временских лимита у случају да неко није на вријеме наручио и сл.')" v-model="item.no_time_limit_user"  :false-value="0" :true-value="1" @change="setTimeLimit(item, index)"></v-checkbox>
          </span>
        </template>

        <template v-slot:item.actions="{ item, index }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                class="px-2"
                v-if="item.type === 'table'"
                @click="createTableQr(item)"
                v-bind="attrs"
                v-on="on"
              >
                mdi-qrcode
              </v-icon>
            </template>
            <span>Table QR</span>
          </v-tooltip>
          <v-icon small @click="close2(item,index)" :class="windowSize.x > 1200 ? 'px-2' : 'px-0'">mdi-qrcode-scan</v-icon>
          <v-icon small :class="windowSize.x > 1200 ? 'mr-2' : 'mr-0'" @click="editItem(item)">mdi-pencil</v-icon>
          <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
          <span v-if="item.pivot && idProp == item.pivot.company_id && item.company.is_credit /*&& item.type == 'customer'*/">
            <v-icon small @click="openCredit(item)" style="color: #1e7e34">mdi-currency-eur</v-icon>
          </span>
        </template>
        <template v-slot:no-data>
          <v-btn :style="'border-radius: 8px;border: 1px solid ' + backGround + ';background: none;color: ' + backGround + ';box-shadow: none;text-transform: capitalize'" @click="initialize">
            {{ $t("resets") }}
          </v-btn>
        </template>
      </v-data-table>
      <v-dialog v-model="dialog2" max-width="500px" scrollable>
        <v-card>
          <v-card-title class="primary">
            <span class="headline white--text">{{ $t("qr_code") }}</span>
            <v-spacer></v-spacer>
            <v-btn :title="$t('print')" style="border:none" outlined color="white darken-4" small class="ml-2" @click="test(editedItem)">
              <v-icon class="">mdi-printer</v-icon>
            </v-btn>
            <v-btn color="white darken-1" small text @click="close2">
              <v-icon large>mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text>
            <div class="pt-7 pl-1">
              <v-row justify="center">
  <!--              <vue-qrcode-->
  <!--                :value="-->
  <!--                  JSON.stringify({-->
  <!--                    id: this.selectedQr,-->
  <!--                    secure: this.selectedQrSecret,-->
  <!--                  })-->
  <!--                "-->
  <!--                :options="{ width: 200 }"-->
  <!--                render-as="svg"-->
  <!--                style="border: 2px solid gray"-->
  <!--              ></vue-qrcode>-->
                <v-col cols="5"> <qrcode-vue id="qrkodstampa" :value="JSON.stringify({
                      id: this.selectedQr,
                      secure: this.selectedQrSecret,
                    })" :size="160" level="H" render-as="svg"/></v-col>
                <v-col cols="7">
                <div style="color:#000; !important;font-weight: 900;margin-left: -15px" class="p-3">
                  <div>ID: {{ editedItem.id }}</div>
                  <div>{{ translate(editedItem.name) + " " +translate(editedItem.lastname) }}</div>
                  <div>{{ editedItem.email }}</div>
                </div>
                </v-col>
              </v-row>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogCanSeeMenu" max-width="70%" scrollable>
        <v-card>
          <v-card-title class="primary" style="width: 100%">
            <div style="display: flex;justify-content: space-between;width: 100%">
            <span style="font-size: 18px !important; justify-content: space-between;width: 100%" class="headline white--text text-center d-flex">
              {{  userMenuItems ? userMenuItems.name + ' ' + userMenuItems.lastname + ' (' + userMenuItems.id + ')' : '' }}
            </span>
            <v-btn color="white" class="ml-auto" text @click="dialogCanSeeMenu = false">
              <v-icon class="mr-2">mdi-close</v-icon>
            </v-btn>
            </div>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text>
            <h4 class="pl-3 mt-3">{{ translate('Избор менија (шта корисник може да види) ') }}</h4>
            <section class="menu-container">
              <v-col class="menu-item" :key="index" cols="auto" v-for="(check, index) in menuItems">
                <div class="menu-item-wrapper">
                <v-checkbox v-if="check.title == 'restaurants' && userMenuItems"
                    v-model="userMenuRestaurant[userMenuItems.id]"
                    :label="$t('restaurants')"
                    :value="userMenuRestaurant[userMenuItems.id]"
                    @change="setAddRestoran(userMenuItems, userMenuItems.id)"
                ></v-checkbox>
                <v-checkbox v-else-if="check.title == 'settings' && userMenuItems"
                  v-model="userMenuItems.pivot.settings"
                  :label="$t('settings')"
                  :value="userMenuItems.pivot.settings"
                  @change="setPodesavanja(userMenuItems, userMenuIndex)"
                ></v-checkbox>
                <v-checkbox v-else multiple
                  v-model="selectedMenuItems"
                  :label="$t(check.title)"
                  :value="check.title"
                ></v-checkbox>
                </div>
              </v-col>
            </section>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialogCanSeeMenu = false">
                <span style="color: #fb6d3b">{{ $t("cancel") }}</span>
              </v-btn>
              <v-btn style="background: #fb6d3b;color: #fff" color="primary" dark text @click="storeUserMenu()">
                <span style="color: #fff">{{ $t("save") }}</span>
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="openModalCredit" max-width="800px" scrollable>
        <v-card>
          <v-card-title class="primary" style="width: 100%">
            <span style="font-size: 18px !important; justify-content: space-between;width: 100%" class="headline white--text text-center d-flex">
              {{ $t("credit_only") }}
               <span>
                 <v-btn :style="! canSeeEditCredit ? '' : 'background:#fff; color:#fb6d3b'" style="border: 1px solid #fff;padding: 6px;border-radius: 5px" color="darken-1" text @click="editCredit()">
                  <span :style="! canSeeEditCredit ? 'color:#fff' : 'color: #fb6d3b'" style="color: #fff;font-size: 11px;">{{ $t("edit_credit") }}</span>
                </v-btn>
                <v-btn :style="canSeeAddCredit ? 'background:#fff' : ''" class="ml-3" color="primary" style="color: #fff;border: 1px solid #fff;padding: 6px;border-radius: 5px" dark text @click="addCredit()">
                  <span :style="canSeeAddCredit ? 'color:#fb6d3b' : 'color: #fff'" style="color: #fff;font-size: 11px;">{{ $t("add_credit1") }}</span>
                </v-btn>
               </span>
<!--              <span>{{ titleCase(translate(modalCreditItems.name)) + ' ' + titleCase(translate(modalCreditItems.lastname)) }} | ID: {{ modalCreditItems.id }}</span>-->
            </span>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text>
            <div v-if="canSeeEditCredit">
              <p class="mb-0 mt-3 mb-1 font-weight-bold">{{ translate('Историја кредита') }}:</p>
              <p class="text-center" v-if="! modalCreditUser.credit.length">{{ translate('Корисник нема унесен кредит') }}</p>
              <v-data-table v-else
                  :headers="headersCredit"
                  :items="modalCreditUser.credit"
                  sort-by="calories"
                  class="elevation-1"
                  dense
                  id='print'
                  :search="search"
              >
                <template
                    v-for="header in headers"
                    v-slot:[`header.${header.value}`]="{ header }"
                >
                  {{ translate(header.text) }}
                </template>
                <template
                    v-for="values in headers"
                    v-slot:[`item.${values.value}`]="{ item }"
                >
                  {{ translate(item[values.value]) }}
                </template>
                <template v-slot:item.credit_type="{ item }">
                  {{ getCreditName(item.credit_type_id) }}
                </template>
                <template v-slot:item.date_to="{ item }">
                  {{ formatDate(validTo(item.credit_type_id)) }}
                </template>
                <template v-slot:item.date="{ item }">
                  {{ formatDate(item.date) }}
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-icon small class="mr-2" @click="creditEdit(item)">mdi-pencil</v-icon>
                  <v-icon small @click="creditDelete(item)"> mdi-delete </v-icon>
                </template>
                <template v-slot:no-data>
                  <v-btn color="primary" @click="initialize">
                    {{ $t("resets") }}
                  </v-btn>
                </template>
              </v-data-table>
              <div v-if="editCredtiItem">
                <p class="mb-0 mt-3 mb-1 font-weight-bold">{{ $t('credit') }}:</p>
                <v-select class="mt-2"
                  :label="$t('credit_edit')"
                  v-model="creditItemEdit.credit_type_id"
                  :items="storedCredits"
                  hide-details
                  outlined
                  v-on:change="selectCredit"
                  dense
                  item-value="id"
                  item-text="name"
                ></v-select>
                <v-row>
                  <v-col>
                    <v-text-field class="mt-3"
                                  oninput="if(this.value < 0) this.value = 0;"
                                  v-model="creditItemEdit.duguje"
                                  type="number"
                                  :label="translate('*Унесите Допунy...')"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field class="mt-3"
                                  type="number"
                                  oninput="if(this.value < 0) this.value = 0;"
                                  v-model="creditItemEdit.potrazuje"
                                  :label="translate('*Унесите Потрошњy...')"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </div>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="openModalCredit = false">
                  <span style="color: #fb6d3b">{{ $t("cancel") }}</span>
                </v-btn>
                <v-btn v-if="canSeeButtons" style="background: #fb6d3b;color: #fff" color="primary" dark text @click="storeUserCreditEdit()">
                  <span style="color: #fff">{{ $t("save") }}</span>
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </div>
            <div v-if="! canSeeEditCredit">
              <v-select
                class="mt-5"
                :label="$t('choose_credit')"
                v-model="modalCreditItems.izabrani_kredit"
                :items="storedCredits"
                hide-details
                outlined
                v-on:change="selectCredit"
                dense
                item-value="id"
                item-text="name"
              ></v-select>
              <p style="color: #949494; font-size: 11px">
                {{ modalCreditItems.izabrani_kredit ? translate('Валидно од: ') + formatDate(storedCredits.find(id => id.id == modalCreditItems.izabrani_kredit).date_from) + ' - ' + translate('Валидно до: ') + formatDate(storedCredits.find(id => id.id == modalCreditItems.izabrani_kredit).date_to) + ' | ' + translate('Проценат: ') + storedCredits.find(id => id.id == modalCreditItems.izabrani_kredit).percent + ' | ' + translate('Рабат: ') + storedCredits.find(id => id.id == modalCreditItems.izabrani_kredit).rabat : '' }}
              </p>
              <v-row justify="center" class="pt-5">
                <v-text-field
                  class="px-4"
                  type="number"
                  v-model="modalCreditItems.kredit"
                  :label="translate('*Унесите кредит...')"
                ></v-text-field>
              </v-row>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="openModalCredit = false">
                  <span style="color: #fb6d3b">{{ $t("cancel") }}</span>
                </v-btn>
                <v-btn style="background: #fb6d3b;color: #fff" color="primary" dark text @click="storeUserCredit()">
                  <span style="color: #fff">{{ $t("add") }}</span>
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="openModalSearchUser" max-width="800px" scrollable>
        <v-card>
          <v-card-title class="primary" style="width: 100%">
            <div style="display: flex;justify-content: space-between;width: 100%">
            <span style="font-size: 18px !important; justify-content: space-between;width: 100%" class="headline white--text text-center d-flex">
              {{ $t("users") }}
            </span>
            <v-btn color="white darken-1" text @click="openModalSearchUser = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            </div>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text>
            <div>
              <v-row justify="center" class="pt-5">
                <v-text-field
                    class="px-4"
                    type="number"
                    v-model="search_user_id"
                    :label="translate('* Унеси ИД корисника...')"
                ></v-text-field>
              </v-row>
              <p v-if="userByIdError" class="my-3" style="font-weight: bold">{{ userByIdError }}</p>
              <section v-if="search_user_id_data">
                <v-row justify="center">
                  <v-col cols="5"> <qrcode-vue id="qrkodstampa" :value="JSON.stringify({
                      id: this.selectedQr,
                      secure: this.selectedQrSecret,
                    })" :size="160" level="H" render-as="svg"/></v-col>
                  <v-col cols="7">
                    <div style="color:#000; !important;font-weight: 900;margin-left: -15px" class="p-3">
                      <div>ID: {{ search_user_id_data.data.id }}</div>
                      <div>{{ translate(search_user_id_data.data.name) + " " + translate(search_user_id_data.data.lastname) }}</div>
                      <div>{{ search_user_id_data.data.email }}</div>
                      <div>{{ search_user_id_data.data.phone }}</div>
                      <div>{{ $t('firm') }}:</div>
                      <div v-for="company in search_user_id_data.data.companies">
                        {{ company.name }}
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </section>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="openModalSearchUser = false">
                  <span style="color: #fb6d3b">{{ $t("cancel") }}</span>
                </v-btn>
                <v-btn style="background: #fb6d3b;color: #fff" color="primary" dark text @click="searchUserById()">
                  <span style="color: #fff">{{ $t("search") }}</span>
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="addMultiple" max-width="100%" scrollable>
        <v-card id="korisnici-za-stampu">
          <v-card-title class="primary" style="width: 100%">
            <span style="font-size: 18px !important; justify-content: space-between;width: 100%" class="headline white--text text-center d-flex no-print">
               <span>{{ translate(restaurantName) }} - <span class="text-lowercase">{{ $t("add_worker") }}</span></span>
              <span>
                <v-btn style="border-radius: 8px;border: 1px solid #fff;background: none;color: #fff;box-shadow: none;text-transform: capitalize" @click="print('korisnici-za-stampu')" small class="mt-2">
                  <v-icon style="color:#fff" class="mr-2">mdi-printer</v-icon>
                  <span style="color:#fff">
                    {{ $t("print") }}
                  </span>
                </v-btn>
                <v-btn color="white darken-1" text class="no-print" @click="addMultiple = false"><v-icon>mdi-close</v-icon></v-btn>
              </span>
            </span>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text>
            <div class="mt-4">
              <div v-for="(usr, index) in multipleUsers" style="display: flex;justify-content: space-between;align-items: center;height: 62px">
                <span class="pr-2 block">{{ index + 1 }}. </span>
                <v-col>
                  <v-text-field v-model="usr.name" :rules="[$t('required_field')]" :label="$t('name')"></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field v-model="usr.lastname" :rules="[$t('required_field')]" :label="$t('last_name')"></v-text-field>
                </v-col>
                <v-col :title="translate('Предлог ime.prezime@domen_firme')">
                  <v-text-field v-model="usr.email" :rules="[$t('required_field')]" :label="$t('email_or_username')"></v-text-field>
                  <p style="font-size: 15px;color: #c20606;border: 1px solid darkred" v-if="errorMessageEmail">{{ errorMessageEmail }}</p>
                </v-col>
                <v-col>
                  <v-text-field
                      v-model="usr.password"
                      :rules="[$t('required_field')]"
                      :label="$t('password')"
                  ></v-text-field>
                </v-col>
                <v-col v-if="$store.state.company&&$store.state.company.children.length>0&&formTitle=='Измјена корисника'">
                  <v-select
                      v-if="$store.state.company"
                      :items="$store.state.company.children"
                      :label="$t('office')"
                      item-text="name"
                      item-value="id"
                      :rules="[$t('required_field')]"
                      v-model="usr.company_id"
                  ></v-select>
                </v-col>
                <v-col>
                  <v-select
                    :disabled="usr.type === 'admin'"
                    :items="
                      $route.name === 'restoran-id-korisnici'
                        ? [{text: $t('restaurant'),val:'restaurant'},
                           {text:translate('QR скенер / подела наруџби'),val:'payment'},
                           {text:translate('Извештаји / наруџбе радник'),val:'report'},
                           {text:translate('Извештаји / наруџбе безуговорца'),val:'report_guest'},
                           {text:$t('stock'),val:'warehouse'},
                           {text:$t('table'),val:'table'},
                           // {text:$t('delivery1'),val:'delivery'}
                          ]
                        : $route.params.delivery && $route.params.delivery === 'dostava' ?
                           [ {text: translate('Достављач'), val:'dostava'},
                           { text: translate('Админ - Достава'), val:'admin_delivery'},
                          ]
                        : [{text: $t('worker'), val:'customer'},
                           {text:translate('Компанија - администратор'),   val:'company'},
                           {text:translate('Пријем / Испорука оброка'), val:'recipient'},
                           {text:translate('Гост'), val:'guest'},
                            {text:$t('table'),val:'table'},
                          ]
                    "
                    :label="$t('user_type')"
                    :item-text="item => translate(item.text)"
                    item-value="val"
                    :rules="[$t('required_field')]"
                    v-model="usr.type"
                  ></v-select>
                </v-col>
                <template v-if="usr.type === 'recipient'">
                <v-col>
                  <v-switch
                      v-model="usr.is_contract"
                      inset
                      :label="$t('is_contract')"
                      :false-value="0"
                      :true-value="1"
                      dense
                  ></v-switch>
                </v-col>
                <v-col>
                  <v-switch
                      v-model="usr.is_non_contract"
                      inset
                      :label="$t('is_non_contract')"
                      :false-value="0"
                      :true-value="1"
                      dense
                  ></v-switch>
                </v-col>
              </template>
                <v-card flat v-if="usr.type == 'recipient'">
                  <v-autocomplete
                      v-model="searchs"
                      :items="companies[0].children.filter((i) => i.name)"
                      dense
                      filled
                      multiple
                      :label="$t('choose_office')"
                      item-text="name"
                      item-value="id"
                      clear
                      chips
                      small-chips
                  >
                    <v-btn
                        color="primary"
                        slot="prepend-item"
                        @click="searchs=[]"
                        small
                        class="mx-4"
                    >
                      {{$t("display_all")}}
                    </v-btn>
                  </v-autocomplete>
                  <div >
                    <template
                        v-for="(company) in searchs && searchs.length > 0
                            ? companies[0].children.filter((i) => searchs.includes(i.name))
                            : companies[0].children"
                        dense
                    >
                    </template>
                  </div>
                </v-card>
                <v-col>
                    <v-text-field
                        v-model="usr.external_code"
                        :label="translate('Ekstrni ID')"
                    ></v-text-field>
                  </v-col>
                <v-btn class="no-print" color="red darken-1" text @click.prevent="removeNewUser(index)">
                  <v-icon small> mdi-delete </v-icon>
                </v-btn>
              </div>
              <div class="text-right no-print">
                <v-btn color="blue darken-1" text @click.prevent="addNewUser">
                  <span style="color: #fb6d3b">{{ $t("add") }}</span>
                </v-btn>
              </div>
              <v-card-actions class="no-print">
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="cancelMultiple()">
                  <span style="color: #fb6d3b">{{ $t("cancel") }}</span>
                </v-btn>
                <v-btn style="background: #fb6d3b;color: #fff" color="primary" @click.prevent="multipleUserAdd()" dark text>
                  <span style="color: #fff">{{ $t("save") }}</span>
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogTableQr" max-width="500px" scrollable>
        <v-card>
          <v-card-title class="primary">
            <span class="headline white--text">{{ $t("qr_code") }} #{{ editedItem.id }}</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="dialogTableQr = false" color="white">
              <v-icon large>mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text>
            <v-row justify="center" class="pt-5">
              <v-col cols="12" class="pa-0" align="center">
                <vue-qrcode
                        :value="'https://konobar.ordera.app/login?activation=' + activation"
                        :options="{ width: 200 }"
                        style="border: 2px solid gray"
                ></vue-qrcode>
              </v-col>
              <v-col cols="12" class="pa-0" align="center">
                {{ translate(editedItem.name) + " " + translate(editedItem.lastname) }}
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="confirm_user_change_company" max-width="500px" scrollable>
        <v-card>
          <v-card-title class="primary">
            <span class="headline white--text">{{ translate('Промена  фирме') }}</span>
          </v-card-title>

          <v-card-actions class="py-3">
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="confirm_user_change_company = false">
              {{ $t("close") }}
            </v-btn>
            <v-btn color="primary darken-1" text @click.prevent="changeCompany()">
              {{ $t("edit") }}
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="addedMultipleUsers" max-width="600px" scrollable>
        <v-card>
          <v-card-title class="headline relative text-center w-100">
            <div style="position: absolute;top: -15px;right: -25px;cursor: pointer" @click.prevent="addedMultipleUsers = false"><v-icon x-small>mdi-close</v-icon></div>
            <p style="width: 100%" class="text-left font-weight-normal" v-if="addedMultipleUsersData && addedMultipleUsersData.existing_users && Array.isArray(addedMultipleUsersData.existing_users)">{{ translate('Постојећи корисници') }}: {{ addedMultipleUsersData.existing_users.length }}</p>
            <p style="width: 100%" class="text-left font-weight-normal" v-else>
              {{ translate('Постојећи корисници') }}:
              <span>{{ addedMultipleUsersData.existing_users && addedMultipleUsersData.existing_users[1] ? addedMultipleUsersData.existing_users[1].email : '' }}</span>
              <span>{{ addedMultipleUsersData.existing_users && addedMultipleUsersData.existing_users[2] ? + ', ' + addedMultipleUsersData.existing_users[2].email : '' }}</span>
              <span>{{ addedMultipleUsersData.existing_users && addedMultipleUsersData.existing_users[3] ? + ', ' +  addedMultipleUsersData.existing_users[3].email : '' }}</span>
              <span>{{ addedMultipleUsersData.existing_users && addedMultipleUsersData.existing_users[4] ? + ', ' +  addedMultipleUsersData.existing_users[4].email : '' }}</span>
            </p>
            <p style="width: 100%" class="text-left font-weight-normal" v-if="addedMultipleUsersData && Object.keys(addedMultipleUsersData.new_users).length > 0">{{ translate('Нови корисници') }}: {{ Object.keys(addedMultipleUsersData.new_users).length }}</p>
            <p style="width: 100%" class="text-left font-weight-normal" v-if="addedMultipleUsersData && addedMultipleUsersData.no_email_users.length > 0">{{ translate('Корисници без емаил-а') }}: {{ addedMultipleUsersData.no_email_users.length }}</p>
          </v-card-title>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import VueQrcode from "@chenfengyuan/vue-qrcode";
import axios from "../plugins/axios";
import conf from "../config";
import moment from "moment";
import QrcodeVue from "qrcode.vue";
// import importExcel from '../components/import-excel'

export default {
  components: {
    QrcodeVue,
    VueQrcode,
    // importExcel
  },
  data: () => ({
    windowSize: {
      x: 0,
      y: 0,
    },
    conf: conf,
    addMultiple: false,
    openModalSearchUser: false,
    confirm_user_change_company: false,
    moment:moment,
    dialog: false,
    dialogTableQr: false,
    dialogImport: false,
    openModalCredit: false,
    canSeeEditCredit: false,
    canSeeButtons: false,
    editCredtiItem: false,
    addedMultipleUsers: false,
    search_user_id: '',
    search_user_id_data: '',
    creditItemEdit: '',
    canSeeAddCredit: true,
    modalCreditItems: '',
    modalCreditUser: '',
    multipleUsers: [{
      address:"",
      all_cooperation:"",
      can_change_restoran_company:0,
      children:"",
      code:"",
      company_delivery:Array[0],
      email:"",
      external_code:null,
      image_path:null,
      is_contract:1,
      is_non_contract:0,
      is_transferable:0,
      lastname:"",
      name:"",
      no_time_limit_user:0,
      notification:null,
      password:"",
      phone:"",
      state:1,
      workPos:"",
    }],
    search: "",
    searchs: [],
    idProp: null,
    kredit: '',
    add_restoran: [],
    podesavanja: [],
    dialogDelete: false,
    restaurantName: "",
    items: ["Email", "Telefon", "Ne zelim primati notifikacije"],
    menuItems: [
      { title: "meal_main"},
      { title: "report"},
      { title: "guest_report"},
      { title: "post_report"},
      { title: "stock"},
      // { title: "delivery"},
      { title: "companies"},
      { title: "companies_delivery"},
      { title: "meals_main"},
      { title: "groups"},
      { title: "menu_types"},
      { title: "groceri"},
      { title: "settings"},
      { title: "gallery"},
      { title: "restaurants"},
      { title: "users"},
      { title: "customers_restoran"},
      { title: "history"},
      // { title: "orders"},
      // { title: "branches"},
      { title: "notifications"},
      // { title: "orders_guest"},
    ],
    headersCredit: [
      {
        text: "Тип кредита",
        align: "start",
        value: "credit_type",
      },
      {
        text: "Допуна",
        align: "start",
        value: "duguje",
      },
      {
        text: "Потрошња",
        align: "start",
        value: "potrazuje",
      },
      {
        text: "Датум до",
        align: "start",
        value: "date_to",
      },
      {
        text: "Датум",
        align: "start",
        value: "date",
      },
      {
        text: "Акције",
        align: "start",
        value: "actions",
      },
    ],
    headers: [
      {
        text: "Id",
        align: "start",
        value: "id",
      },
      {
        text: "name",
        align: "start",
        value: "name",
        sortable: true,
      },
      {
        text: "last_name",
        align: "start",
        value: "lastname",
        sortable: true,
      },
      {
        text: "email",
        align: "start",
        value: "email",
        sortable: true,
      },
      {
        text: "role",
        align: "start",
        value: "type",
        sortable: true,
      },
      {
        text: "menu",
        align: "start",
        value: "menu",
        sortable: false,
      },
      {
        text: "status",
        align: "start",
        value: "state",
        sortable: true,
      },
      {
        text: "report_workers",
        align: "start",
        value: "can_report",
        sortable: true,
      },
      {
        text: "time_limit",
        align: "start",
        value: "time_limit",
        sortable: true,
      },
      // {
      //   text: "+ Ресторан",
      //   align: "start",
      //   value: "restoran",
      //   sortable: true,
      // },
      // {
      //   text: "Подешавања",
      //   align: "start",
      //   value: "podesavanja",
      //   sortable: true,
      // },
      { text: "actions", value: "actions", sortable: false },
    ],
    desserts: [],
    usersOriginal: '',
    editedIndex: -1,
    editedItem: {
      name: "",
      all_cooperation: "",
      lastname: "",
      address: "",
      phone: "",
      email: "",
      private_email: "",
      password: "",
      code: "",
      workPos: "",
      children: "",
      state: 1,
      is_contract: 1,
      is_non_contract: 0,
      // no_time_limit: 0,
      no_time_limit_user: 0,
      is_transferable: 0,
      can_change_restoran_company: 0,
      company_delivery: [],
      image_path: null,
      notification: null,
      external_code: null,
    },
    defaultItem: {
      name: "",
      all_cooperation: "",
      lastname: "",
      address: "",
      phone: "",
      email: "",
      private_email: "",
      password: "",
      code: "",
      workPos: "",
      children: "",
      state: 1,
      is_contract: 1,
      is_non_contract: 0,
      is_transferable: 0,
      // no_time_limit: 0,
      no_time_limit_user: 0,
      can_change_restoran_company: 0,
      image_path: null,
      notification: null,
      external_code: null,
    },
    selectedQr: false,
    selectedQrSecret: false,
    userByIdError: '',
    dialog2: false,
    errorMessageEmail: '',
    companies: '',
    storedCredits: '',
    credit: '',
    activation: null,
    parentCompanies: '',
    parentCompaniesOriginal: '',
    companyChildren: [],
    addedMultipleUsersData: '',
    children: '',
    card: {
      width: '90',
      height: '50',
      marginTop: '25',
      fontSize: '13',
      type: 2,
      visible_fields: [1,2,3,4],
    },
    qrIndex: '',
    current_company: '',
    dialogCanSeeMenu: false,
    selectedMenuItems: [],
    selectedMenuItemsOriginal: [],
    setMenuUserId: '',
    userMenuItems: '',
    userMenuIndex: '',
    userMenuRestaurant: [],
    userMenuSettings: []
  }),
  mounted() {
    this.onResize()
    this.menuItems.filter(item => {
      this.selectedMenuItemsOriginal.push(item.title)
    })
  },
  computed: {
    filteredItems() {
      if (!this.search) {
        return this.desserts;
      }
      const searchLower = this.normalizeString(this.search);

      return this.desserts.filter(item =>
          Object.values(item).some(value =>
              this.normalizeString(String(value)).includes(searchLower)
          )
      );
    },
    formTitle() {
      return this.editedIndex === -1 ? this.$t('new_user') : this.$t('edit_user');
    },
    backGround() {
      if(this.$store.state.restaurant) {
        return this.$store.state.restaurant.settings
          ? this.$store.state.restaurant.settings.topbar.background
          : 'primary'
      }
      else if(this.$store.state.company &&  this.$store.state.company.settings &&  this.$store.state.company.settings.topbar) {
        return this.$store.state.company.settings
          ? this.$store.state.company.settings.topbar.background
          : 'primary'
      } else {
        return 'primary'
      }
    }
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    idProp() {
      if (this.$store.state.user.type != 'admin_delivery') {
        axios.post('/companies/get-active-credit', {'company_id': this.idProp}).then(({data}) => {
          data.filter(item => {
            item.credit_type_id = item.id
          })
          this.storedCredits = data
        })
      }
    },
    '$route.params': {
      handler(newParams) {
        this.children = newParams.children
      },
      immediate: true,
    },
  },

  created() {
    // this.getCredit()
    this.initialize();
    this.getParentCompanies();
    this.getCompanies();
    // if(this.$store.state.user.type == 'admin') {
    //   this.headers.splice(6, 0, {
    //     text: '+ ' + this.translate('Ресторан'),
    //     align: "start",
    //     value: "restoran",
    //     sortable: false,
    //   },
    //   {
    //     text: this.translate("Подешавања"),
    //     align: "start",
    //     value: "podesavanja",
    //     sortable: false,
    //   });
    // }
  },

  methods: {
    storeUserMenu() {
      this.initialize();
      let priorities = ["meal_main", "report", "guest_report", "companies", "stock", "meals_main", "groups", "menu_types", "groceri", "gallery", "restaurants"];
      this.selectedMenuItems.sort((a, b) => {
        if (a['meal_main']) {
          if (a === "meal_main") return -1;
          if (b === "meal_main") return 1;
        }
        if (a['report']) {
          if (a === "report") return -1;
          if (b === "report") return 1;
        }
        if (a['guest_report']) {
          if (a === "guest_report") return -1;
          if (b === "guest_report") return 1;
        }
        if (a['companies']) {
          if (a === "companies") return -1;
          if (b === "companies") return 1;
        }
        if (a['stock']) {
          if (a === "stock") return -1;
          if (b === "stock") return 1;
        }
        if (a['meals_main']) {
          if (a === "meals_main") return -1;
          if (b === "meals_main") return 1;
        }
        if (a['groups']) {
          if (a === "groups") return -1;
          if (b === "groups") return 1;
        }
        if (a['menu_types']) {
          if (a === "menu_types") return -1;
          if (b === "menu_types") return 1;
        }
        if (a['groceri']) {
          if (a === "groceri") return -1;
          if (b === "groceri") return 1;
        }
        if (a['gallery']) {
          if (a === "gallery") return -1;
          if (b === "gallery") return 1;
        }
        if (a['restaurants']) {
          if (a === "restaurants") return -1;
          if (b === "restaurants") return 1;
        }

        return a.localeCompare(b);
      });

      if(! this.userMenuRestaurant[this.userMenuItems.id]) {
        this.selectedMenuItems.filter((item, index) => {
         if(item && item == 'restaurants') {
           this.selectedMenuItems.splice(index, 1);
         }
        })
      } else if( this.userMenuRestaurant[this.userMenuItems.id]) {
        this.selectedMenuItems.push('restaurants');
      }

      this.selectedMenuItems.sort((a, b) => {
        if (priorities.includes(a) && priorities.includes(b)) {
          return priorities.indexOf(a) - priorities.indexOf(b);
        }
        if (priorities.includes(a)) return -1;
        if (priorities.includes(b)) return 1;

        return 0;
      });

      axios.post('/user/user-menus-edit', { menu: this.selectedMenuItems, user_id: this.setMenuUserId}).then(({data}) => {
        if(data) {
          this.dialogCanSeeMenu = false
          this.desserts.filter(item => {
            if (item.id == this.setMenuUserId) {
              item.user_menu.menu1 = data.menu1
            }
          })
        }
        this.$forceUpdate()
      })
    },
    userCanSeeMenu(item, index) {
      this.userMenuItems = item
      this.userMenuIndex = index
      if (item.user_menu) {
        this.selectedMenuItems = []
        let meni = JSON.parse(item.user_menu.menu1)
        meni.filter(item => {
          this.selectedMenuItems.push(item.title)
        })
      } else {
        this.selectedMenuItems = this.selectedMenuItemsOriginal
      }

      this.dialogCanSeeMenu = true
      this.setMenuUserId = item.id
    },
    searchUserById() {
      this.search_user_id_data = ''
      axios.post('/user/search-user-by-id', { user_id: this.search_user_id }).then(({data}) => {
        if (data) {
          this.search_user_id = ''
          this.search_user_id_data = data
          this.selectedQrSecret = data.data.qrCode
          this.selectedQr = data.data.id;
          this.userByIdError = ''
        }
      }).catch((error) => {
        this.userByIdError = error.response.data.message
      });
    },
    openModalSearchUserById() {
      this.openModalSearchUser = true
    },
    test(item) {
      let isTable = this.desserts.every(i => i.company && i.company.type === 'table')
      let column = isTable ? 4 : this.card.type == 2 ? 2 : 1

      let el = document.getElementById('print');
      let trs = el.getElementsByTagName('tbody')
      let arr = trs[0].getElementsByTagName('tr')[this.qrIndex]

      let html = '<table>'
      let i = 1
      // arr.forEach((tr, index) => {
      let tr = arr
      let index = this.qrIndex
        if(index%column === 0) {
          i = 1
          html += '<tr>'
        }

        let tds = tr.getElementsByTagName('td')
        let tdss = document.getElementById('qrkodstampa')
        if(tds.length) {
          let id = this.card.visible_fields[0] == 1 ? tds[0].innerHTML : ''
          let name = (this.card.visible_fields[1] == 2 ? tds[1].innerHTML : '') + ' ' + (this.card.visible_fields[2] == 3 ? tds[2].innerHTML : '')
          let email = this.card.visible_fields[3] == 4 ? tds[3].innerHTML : ''

          let style2 = index%column === 0 ? 'border-left: 1px solid #f1f1f1; padding-right: 6px' : 'padding-right: 6px'
          let canvas = tdss.getElementsByTagName('svg')

          const serializer = new XMLSerializer();
          const svgString = serializer.serializeToString(canvas[0]);
          const encodedData = 'data:image/svg+xml,' + encodeURIComponent(svgString);
          html += '<td style="'+style2+'">'
          html += `<img src="${encodedData}" style="padding-left: ${this.card.marginTop}px;width: ${this.card.width*0.4}mm; height: ${this.card.width*0.4}mm"/>`
          if(isTable) html += `<div style="font-size: ${this.card.fontSize}px; text-align: center">${name}</div>`
          html += '</td>'

          let style = index%column === 0 ? 'border-right: 1px solid #f1f1f1;' : 'border-right: 1px solid #f1f1f1;'

          if(!isTable) {
            html += '<td style="'+style+'">'
               html += `<div style="margin-left: 10px;display: flex; flex-direction: column; height: ${this.card.height}mm; justify-content: center; width: ${this.card.width*0.6}mm;">`
                 html += `<div style="height: ${this.card.marginTop === 'auto' ? (this.card.height*0.4 - 15) : (this.card.height - this.card.marginTop)}mm; line-height: 1.5">`
                   html += `<div style="font-size: ${this.card.fontSize}px">${id}</div>`
                   html += `<div style="font-size: ${this.card.fontSize}px">${name}</div>`
                   html += `<div>${email}</div>`
                 html += `</div>`
                 html += `<div style="text-align: end"><span style="margin-right: 10px; color: #f1f1f1">ordera.app</span> <img src="./o-large.svg" width="30px" style="margin-bottom: -4px; margin-right: ${this.card.marginTop}px"/></div>`
               html += `</div>`
            html += '</td>'
          }
        }
        if(i === column) html += '</tr>'
        i++
      // })
      html += '</table>'

      html = [
        '<!DOCTYPE html>'+
        '<html>',
        '   <head>',
        '   <style>td{border-bottom: 1px solid #f1f1f1;}</style>',
        '   </head>',
        '   <body onload="window.print()" onafterprint="window.close()" style="font-family: Roboto, sans-serif; font-size: '+ (this.card.fontSize - 2) +'px!important;">',
        html,
        '   </body>',
        '</html>'
      ].join('')
      let win = window.open("",
          "",
          "left=0,top=0,toolbar=0,scrollbars=0,status=0"
      );
      win.document.open();
      win.document.write(html);
      win.document.close();

    },
    onResize() {
      this.windowSize = {x: window.innerWidth, y: window.innerHeight}
    },
    addUsersToParentCompany() {
      let users = ''
      if (this.companyChildren.length > 0) {
        users = []
        this.parentCompanies.filter(item => {
          if (item.users && item.users.length > 0) {
            item.users.filter(user => {
              if (this.companyChildren.includes(user.pivot.company_id)) {
                users.push(user)
              }
            })
          }
        })
        let hasOffice = false
        this.headers.filter((item, index) => {
          if (item.text == 'office') {
            hasOffice = true
          }
        })
        if (! hasOffice) {
          this.headers.splice(3, 0, {
            text: "office",
            align: "start",
            value: "company",
            sortable: true,
          });
        }
      }
      if (this.companyChildren.length == 0) {
        users = this.usersOriginal
        this.headers.filter((item, index) => {
          if (item.text == 'office') {
            this.headers.splice(index, 1);
          }
        })
      }
      this.desserts = users
      this.$forceUpdate()
    },
    addAllUsersToParent() {
      if (this.companyChildren.length > 0) {
        this.companyChildren = []
        this.addUsersToParentCompany()
      } else
      if (this.companyChildren.length == 0) {
        let allFirmsId = []
        this.parentCompanies.filter(firm => {
          allFirmsId.push(firm.id)
        })
        this.companyChildren = allFirmsId
        this.addUsersToParentCompany()
      }
    },
    getItemText(item) {
      return this.translate(`${item.name}`);
    },
    normalizeString(str) {
      return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
    },
    openChangeCompany() {
      this.confirm_user_change_company = true
    },
    changeCompany() {
      axios.post('/companies/change-user-company', this.editedItem).then(({data}) => {
        this.desserts.filter((item,index) => {
          if (item.id == data.user_id) {
            this.desserts.splice(index,1)
          }
        })
        this.confirm_user_change_company = false
        this.dialog = false
      })
    },
    getParentCompanies() {
      let route  = "/companies/" + this.$route.params.id + "/parent-companies"
      axios.get(route).then(({data}) => {
       this.parentCompanies = data
        if (Array.isArray(data)) {
          data.filter(firm => {
            if (firm && firm.users && firm.users.length > 0) {
              firm.users.filter(user => {
                user.company = firm.name
              })
            }
          })
        }

       this.parentCompaniesOriginal = data
      });
    },
    createTableQr(user) {
      this.editedItem = Object.assign({}, user);
      axios.get('/user/' + user.id + '/activation').then(res => {
        this.activation = res.data
        this.dialogTableQr = true
      })
    },
    cancelMultiple() {
      this.addMultiple = false
      this.multipleUsers = []
      this.addNewUser()
      this.$forceUpdate()
    },
    multipleUserAdd() {
      axios.post('/companies/' + this.$route.params.id + '/add-multiple-users', this.multipleUsers).then(({data}) => {
        if (data) {
          if (Object.keys(data.data.new_users).length > 0) {
            for (let i in data.data.new_users) {
              this.desserts.push(data.data.new_users[i]);
            }
          }
          this.addedMultipleUsersData = data.data
          this.addedMultipleUsers = true
          setTimeout(() => {
            this.addedMultipleUsers = false
          }, 7000)
          this.addMultiple = false
          this.$forceUpdate()
        }
      })
    },
    removeNewUser(index) {
      this.multipleUsers.splice(index, 1);
    },
    addNewUser() {
      this.multipleUsers.push({
        address:"",
        all_cooperation:"",
        can_change_restoran_company:0,
        children:"",
        code:"",
        company_delivery:Array[0],
        email:"",
        external_code:null,
        image_path:null,
        is_contract:1,
        is_non_contract:0,
        is_transferable:0,
        lastname:"",
        name:"",
        no_time_limit_user:0,
        notification:null,
        password:"",
        phone:"",
        state: 1,
        workPos:"",
      })
    },
    addMultipleUsers() {
      this.addMultiple = true
    },
    setTimeLimit(item, index) {
      axios.post('/user/change-user-time-limit', item).then(({data}) => {
        console.log(data)
      })
    },
    validTo(credit) {
      let date = ''
      this.storedCredits.filter(item => {
        if (item.id == credit) {
          date = item.date_to
        }
      })
      return date
    },
    creditEdit(item) {
      this.canSeeButtons = true
      this.editCredtiItem = true
      this.creditItemEdit = item
    },
    creditDelete(item) {

    },
    getCreditName(credit) {
      let name = ''
      this.storedCredits.filter(item => {
        if (item.id == credit) {
          name = item.name
        }
      })
      return name
    },
    formatDate(date) {
      if (date) {
        return moment(date).format('DD.MM.YYYY')
      } else {
        return '/'
      }

    },
    editCredit() {
      this.canSeeEditCredit = true
      this.canSeeAddCredit = false
    },
    addCredit() {
      this.canSeeAddCredit = true
      this.canSeeEditCredit = false
      this.editCredtiItem = false
      this.canSeeButtons = false
    },
    storeUserCredit() {
      this.modalCreditItems['date'] = moment().format('YYYY-MM-DD HH:mm:ss')
      this.modalCreditItems['credit_type_id'] = this.modalCreditItems.credit_type_id
      this.modalCreditItems['duguje'] = this.modalCreditItems.kredit
      this.modalCreditItems['user_id'] = this.modalCreditUser.id
      axios.post('/user/add-user-credit', this.modalCreditItems).then(({data}) => {
        if(data) {
          this.openModalCredit = false
          this.modalCreditUser.credit.push(data)
          this.modalCreditItems.izabrani_kredit = ''
        }
      })
    },
    storeUserCreditEdit() {
      axios.post('/user/edit-user-credit', this.creditItemEdit).then(({data}) => {
        if(data) {
          this.openModalCredit = false
          this.modalCreditUser.credit.filter(item => {
            if(item.id == data.id) {
              item = data
            }
          })
          this.creditItemEdit = ''
          this.editCredtiItem = false
          this.canSeeButtons = false
        }
      })
    },
    selectCredit() {
      this.storedCredits.filter(item => {
        if (item.id == this.credit) {
          this.modalCreditItems = item
          this.modalCreditItems['naziv'] = item.name
          this.modalCreditItems['id'] = item.id
        }
      })
    },
    titleCase(str) {
      if (str) {
        let splitStr = str.toLowerCase().split(' ');
        for (let i = 0; i < splitStr.length; i++) {
          splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        return splitStr.join(' ');
      } else {
        return  ''
      }
    },
    openCredit(item) {
      this.openModalCredit = true
      this.modalCreditItems = item
      this.modalCreditItems.kredit = item.credit ? item.credit.duguje : ''
      this.modalCreditUser = item
      if (item.credit) {
        this.storedCredits.filter(item1 => {
          if (item1.id == item.credit.credit_type_id){
            this.credit = item.credit.credit_type_id
          }
        })
      } else {
        this.credit = ''
      }
    },
    setAddRestoran(item, index) {
      let data = {}
      data['restoran'] = this.userMenuRestaurant[index]
      data['user'] = item
      axios.post("/user/can-add-sub-restoran", data).then(({data}) => {
        console.log('OK')
      });
    },
    setCanReport(item, index) {
      let data = {}
      data['settings'] = this.podesavanja[index]
      data['user'] = item
      axios.post("/user/can-see-company-reports", data).then(({data}) => {
        console.log('OK')
        this.$forceUpdate()
      });
    },
    setPodesavanja(item, index) {
      let data = {}
      data['settings'] = this.podesavanja[index]
      data['user'] = item
      axios.post("/user/can-see-settings", data).then(({data}) => {
        console.log('OK')
        this.$forceUpdate()
      });
    },
    getCompanies() {
      if (this.$store.state.user.type != 'admin_delivery') {
        axios.get("/companies").then((res) => {
          this.companies = res.data;
        });
      }
    },
    initialize() {
      let route_name = null;
      let all_route = null
      this.idProp = this.$route.params.id;
      if (this.$route.name === "korisnici") {
        route_name = "companies";
        this.idProp = this.$route.params.id;
        this.$route.params.id = this.$store.state.company.id;
        this.$route.params.parent_id = this.$store.state.company.parent_id
        this.$route.params.name = this.$store.state.company.name;
      }
      if (this.$route.name === "firma-id-korisnici") route_name = "companies";
      if (this.$route.name === "restoran-id-korisnici")
        route_name = "restaurants";

      if (this.$route.params.delivery && this.$route.params.delivery == 'dostava') {
        all_route = "/" + route_name + "/" + this.$route.params.id + "/users-delivery"
      } else {
        all_route = "/" + route_name + "/" + this.$route.params.id + "/users"
      }
      axios
        .get(all_route)
        .then((res) => {
          this.restaurantName = res.data.name;
          this.desserts = res.data.users;
          this.usersOriginal = res.data.users;
          if (res.data.is_credit) {
            this.headers.splice( 8,0,{
              text: "credit_only",
              align: "start",
              value: "credit",
              sortable: true,
            })
          }
          this.desserts.filter((des,index) => {
            this.add_restoran[index] = des.pivot.add_restoran;
            this.podesavanja[index] = des.pivot.settings;
            this.userMenuRestaurant[des.id] = des.pivot.add_restoran;
            this.userMenuSettings[des.id] = des.pivot.settings;
          })
        });
    },
    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.editedItem.company_id = this.$route.params.id;

      this.editedItem.all_cooperation = this.editedItem.company && this.editedItem.company.settings ? this.editedItem.company.settings.all_cooperation : ''
      if (this.companies) {
        this.companies.filter(items => {
          if (items.id == item.company ? parseInt(item.company.id) : parseInt(item.company_id)) {
            this.editedItem.children = items.children
          }
        })
      }

      this.dialog = true;
    },
    deleteItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      let _editedIndex = this.editedIndex;
      axios.delete("/user/" + this.editedItem.id).then((res) => {
        this.desserts.splice(_editedIndex, 1);
        this.closeDelete();
      });
    },
    close2(item, index) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      axios.get(`/user/${this.editedItem.id}/hash`).then((res) => {
        this.selectedQrSecret = res.data;
        this.selectedQr = this.editedItem.id;
        this.qrIndex = index
        this.dialog2 = !this.dialog2;
      });
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    upload() {
      let data = new FormData();
      data.append("image", event.target.files[0]);
      data.append("image_path", "users/photos/");

      axios.post("/user/photo", data).then((res) => {
        this.editedItem.image_path = "users/photos/small/" + res.data;
      });
    },
    save() {
      this.errorMessageEmail = ''
      let canClose = true;
      let all_route = null;
      if (! this.editedItem.hasOwnProperty('type')) {
        this.errorMessageEmail = this.translate('Поље тип не сме бити празно')
        canClose = false
        this.dialog = true;
        return
      }
      if (this.editedIndex > -1) {
        let _editedItem = this.editedItem;
        let _editedIndex = this.editedIndex;

        axios.patch("/user/" + this.editedItem.id, this.editedItem)
          .then((res) => {
            Object.assign(this.desserts[_editedIndex], _editedItem);
          });
      } else {
        let _editedItem = this.editedItem;
        //axios.post('/restaurants/' + this.$route.params.id + '/users', this.editedItem, {headers: {restaurant: this.$route.params.id}}).then(res => {
        let route_name = null;
        if (this.$route.name === "firma-id-korisnici" || this.$route.name === "korisnici")
          route_name = "companies";
        if (this.$route.name === "restoran-id-korisnici")
          route_name = "restaurants";

        if (this.$route.params.delivery && this.$route.params.delivery == 'dostava') {
          all_route = "/" + route_name + "/" + this.$route.params.id + "/users-delivery"
          _editedItem.can_report = 0;
        } else {
          all_route = "/" + route_name + "/" + this.$route.params.id + "/users"
        }
        // axios.post("/" + route_name + "/" + this.$route.params.id + "/users", this.editedItem)
        axios.post(all_route, this.editedItem)
            .then((res) => {
            _editedItem.id = res.data.id;
            if (this.$route.params.delivery && this.$route.params.delivery == 'dostava') {
              this.desserts = res.data
            } else {
              // this.desserts.push(_editedItem);
              this.desserts.unshift(_editedItem);
            }
          }).catch((error) => {
            console.log(error.response.data)
          if (error.response.data.status == "failed") {
            this.errorMessageEmail = error.response.data.message
            canClose = false
            this.dialog = true;
            return
          }
          });
      }
      if (canClose){
        this.close();
      }

    },
    print(id) {
      if (id === "korisnici-za-stampu") {
        const prtHtml = document.getElementById(id).innerHTML;
        let stylesHtml = "";
        for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
          stylesHtml += node.outerHTML;
        }
        const WinPrint = window.open("", "", "left=100,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0");
        WinPrint.document.write(`
          <!DOCTYPE html>
          <html>
            <head> ${stylesHtml}</head>
            <body><strong>${prtHtml}</strong></body>
          </html>
        `);
        WinPrint.document.close();
        WinPrint.focus();
        WinPrint.onload = function () {
          WinPrint.print();
          WinPrint.close();
        }
      } else {
        var allRows = document.getElementById(id).querySelector('table').rows;
        for (var i=0; i< allRows.length; i++) {
          allRows[i].lastElementChild.style.display = "none";
        }
        const prtHtml = document.getElementById(id).innerHTML;

        let stylesHtml = "";
        for (const node of [
          ...document.querySelectorAll('link[rel="stylesheet"], style'),
        ]) {
          stylesHtml += node.outerHTML;
        }
        const WinPrint = window.open("", "", "left=0,top=0,width=3950,height=900,toolbar=0,scrollbars=0,status=0");
        WinPrint.document.write(`<!DOCTYPE html>
        <html>
          <head>
            ${stylesHtml}
            <style>
              table {
                overflow: visible;
                font-family: arial, sans-serif;
                border-collapse: collapse;
                width: 100%;
              }
              td, th {
                border: 1px solid #dddddd;
                text-align: left;
                padding: 5px;
              }
              tr:nth-child(even) {
                background-color: #f1f1f1;
              }
            </style>
          </head>
          <body>
            ${prtHtml}
          </body>
        </html>`);
        // WinPrint.document.close();
        // WinPrint.focus();
        // WinPrint.print();
        // WinPrint.close();
        WinPrint.document.close();
        WinPrint.focus();
        WinPrint.onload = function () {
          WinPrint.print();
          WinPrint.close();
        }
        var allRows = document.getElementById(id).querySelector('table').rows;
        for (let i = 0; i < allRows.length; i++) {
          allRows[i].lastElementChild.style.display = "block";
        }
      }
    },
  },
};
</script>
<style>
.custom-data-table td:last-child {
  width: 150px !important;
  white-space: nowrap;
}
.menu-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Four columns, adjust as needed */
  gap: 2px; /* Space between grid items */
  align-items: center;
  margin: 16px;
}

.menu-item {
  display: contents; /* Allows inline elements in a grid row */
  border: 1px solid #cfcfcf;
}
.menu-item-wrapper {display: flex; /* Use flexbox for centering */
  align-items: center; /* Center items vertically */
  justify-content: space-between; /* Spread items horizontally */
  border: 1px solid #ccc; /* Add border around each row */
  border-radius: 5px; /* Optional: rounded corners */
  padding: 5px; /* Add spacing inside the border */
  gap: 5px;
}
.menu-item input {
  margin-right: 8px;
}

.menu-item span {
  text-align: center; /* Align additional content */
}
</style>
<style scoped>
@media print {
  div {
    width: 100vW;
  }
  table {
    padding: 0;
    margin-top: 60px;
    width: 100vw;
    font-size: 80% !important;
    max-width: 100%;
    border-collapse: collapse;
  }
  th, td {
    font-size: 10px !important;
    word-break: break-all;
    padding: 10px;
    word-wrap: break-word;
    white-space: normal;
  }
  .no-print, .v-data-footer {
    display: none;
  }
}
</style>