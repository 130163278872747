import { render, staticRenderFns } from "./Kategorije.vue?vue&type=template&id=3975a62e&scoped=true"
import script from "./Kategorije.vue?vue&type=script&lang=js"
export * from "./Kategorije.vue?vue&type=script&lang=js"
import style0 from "./Kategorije.vue?vue&type=style&index=0&id=3975a62e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3975a62e",
  null
  
)

export default component.exports