<template>
  <div class="qr-list">
    <v-data-table
      id="qrTable"
      :headers="headers"
      :items="desserts"
      sort-by="calories"
      class="elevation-1"
      :items-per-page="parseInt(itemsPerPage)"
      mobile-breakpoint="0"
      :page.sync="page"
      :search="search"
    >
      <template v-for="header in headers" v-slot:[`header.${header.value}`]="{ header }"  >
        {{$t(header.text)}}
      </template>
      <template v-for="values in headers" v-slot:[`item.${values.value}`]="{ item }">
        {{translate(item[values.value])}}
      </template>
      <template v-slot:top>
        <v-toolbar flat class="mt-2">
          <v-toolbar-title>QR</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-toolbar-title>{{ translate(restaurantName) }}: {{ id }}</v-toolbar-title>
           <!-- <v-divider class="mx-4" inset vertical></v-divider>
          <v-btn small color="primary" @click="print()">Print</v-btn> -->
          <v-spacer></v-spacer>
          <v-autocomplete class="pt-2 mr-2" v-if="children && children.length > 0 && parentCompanies.length && parentCompanies.find(company => company.parent_id == 0).id == idProp"
            v-model="companyChildren"
            :items="parentCompanies || []"
            dense
            multiple
            :item-text="getItemText"
            item-value="id"
            @input="addUsersToParentCompany()"
            :label="$t('select_company')"
            clear
            chips
            small-chips
          >
            <v-btn color="primary" slot="prepend-item" @click="addAllUsersToParent()" small class="mx-4">
              {{$t("display_all")}}
            </v-btn>
          </v-autocomplete>
          <v-text-field
            class="pr-4 pb-2"
            v-model="search"
            append-icon="mdi-magnify"
            :label="$t('search')"
            single-line
            hide-details
            dense
          ></v-text-field>
          <v-btn
              color="primary"
              dark
              class="mr-2"
              small
              @click="test"
              outlined
              style="height: 40px">
            <v-icon class="mr-2">mdi-printer</v-icon>{{ $t("print") }}
          </v-btn>
        </v-toolbar>
        <v-toolbar flat class="dimensions gornja_margina">
<!--          <v-toolbar-title>Card</v-toolbar-title>-->
<!--          <v-divider class="mx-1" inset vertical></v-divider>-->
          <v-row >
<!--          <v-spacer></v-spacer>-->
            <v-col cols="4" sm="2"><v-select hide-details outlined dense id=""
               :items="[
                {id: 1, name: translate('1 Колона')},
                {id: 2, name: translate('2 Колоне')}]"
             item-value="id"
             v-model="card.type"
             item-text="name"
             :label="$t('type')">
            </v-select></v-col>
            <v-col cols="8" sm="2"><v-select hide-details outlined dense id=""
              :items="[
                {id: 1, name: translate('ID')},
                {id: 2, name: $t('name')},
                {id: 3, name: $t('last_name')},
                {id: 4, name: $t('email')}]"
              item-value="id"
              multiple
              v-model="card.visible_fields"
              item-text="name"
              :label="translate('Прикажи')">
            </v-select></v-col>
            <v-col cols="3" sm="2"><v-text-field v-model="card.width" label="Width" hide-details outlined dense   suffix="mm"></v-text-field></v-col>
            <v-col cols="3" sm="2"><v-text-field v-model="card.height" label="Height" hide-details outlined dense   suffix="mm"></v-text-field></v-col>
            <v-col cols="3" sm="2"> <v-text-field
                v-model="card.marginTop"
                label="Margin Top"
                hide-details
                outlined
                dense
                suffix="px"
                prepend-inner-icon="mdi-refresh"
                @click:prepend-inner="card.marginTop = 'auto'"
            >
            </v-text-field></v-col>
            <v-col cols="3" sm="2"><v-text-field
              v-model="card.fontSize"
              label="Font Size"
              hide-details
              outlined
              dense
              suffix="px"
          >
          </v-text-field></v-col>
          </v-row>
        </v-toolbar>
      </template>
      <template v-slot:item.qrCode="{item, index}">
        <div style="width: 120px;padding: 30px">
<!--         <vue-qrcode-->
<!--              :value="item.qrCode"-->
<!--              :options="{ width: 300, quantity: 1, type: 'image/webp' }"-->
<!--            ></vue-qrcode>-->
          <div id="print" style="cursor: pointer" @click="singleQrPrint(item, index)">
            <qrcode-vue id="qrkodstampa" :value="item.qrCode" :size="160" level="H" render-as="svg"/>
          </div>
        </div>
      </template>
      <template v-slot:item.type="{ item }">
        {{item.type=="customer"?translate("Радник"):translate("Фирма")}}
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize"> {{translate('Ресетујте')}} </v-btn>
      </template>
      <template v-slot:footer>
        <div style="position: absolute;width: 190px;right: 39%;bottom: 5px;" id="per_page" class="d-flex align-center justify-end pa-2">
          <label for="" style="margin-right: 25px;font-size: 12px;margin-top: 16px;">Items per page:</label>
          <v-text-field
            v-model="itemsPerPage"
            type="number"
            min="1"
            @change="updateItemsPerPage"
            hide-details
            class="mr-4"
            style="width: 20px;font-size: 12px"
          ></v-text-field>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import VueQrcode from "@chenfengyuan/vue-qrcode";
import QrcodeVue from 'qrcode.vue'
import axios from "../plugins/axios";
import conf from '../config'
export default {
  props: ['idProp'],
  components: {
    VueQrcode,
    QrcodeVue
  },
  data: () => ({
    itemsPerPage: 10, // Default items per page
    page: 1, // Current page
    card: {
      width: '90',
      height: '50',
      marginTop: '25',
      fontSize: '13',
      type: 2,
      visible_fields: [1,2,3,4],
    },
    conf: conf,
    search: "",
    restaurantName: "",
    headers: [
      // {
      //   text: "Rb",
      //   value: "rb"
      // },
      {
        text: "Id",
        value: "id"
      },
      {
        text: "name",
        value: "name",
      },
      {
        text: "last_name",
        value: "lastname",
      },
      {
        text: "ek",
        value: "email",
      },
      {
        text: "role",
        value: "type",
      },
      { text: "qr_code", value: "qrCode",sortable: false},
    ],
    desserts: [],
    id: '',
    companyChildren: [],
    parentCompanies: '',
    children: '',
    usersOriginal: [],
  }),

  created() {
    if(this.$store.state.company){
      this.id = this.$store.state.company.id;
    } else {
      this.id = this.idProp;
    }
    if (this.$route.params.idProp) {
      this.id = this.$route.params.idProp;
    }

    if (this.$route.params.res) {
      this.id = this.$route.params.res
      this.initializeRes();
    } else if(this.$route.params.jela) {
        this.initializeJela();
    } else {
      this.initialize();
    }
    if (this.$route.params.children) {
      this.children = this.$route.params.children;
    }
    if (this.$route.params.parentCompanies) {
      this.parentCompanies = this.$route.params.parentCompanies
    }
  },

  methods: {
    getItemText(item) {
      return this.translate(`${item.name}`);
    },
    addAllUsersToParent() {
      if (this.companyChildren.length > 0) {
        this.companyChildren = []
        this.addUsersToParentCompany()
      } else
      if (this.companyChildren.length == 0) {
        let allFirmsId = []
        this.parentCompanies.filter(firm => {
          allFirmsId.push(firm.id)
        })
        this.companyChildren = allFirmsId
        this.addUsersToParentCompany()
      }
    },
    addUsersToParentCompany() {
      let users = ''
      if (this.companyChildren.length > 0) {
        users = []
        this.parentCompanies.filter(item => {
          if (item.users && item.users.length > 0) {
            item.users.filter((user, index) => {
              if (this.companyChildren.includes(user.pivot.company_id)) {
                users.push(user)
              }
            })
          }
        })
      }
      if (this.companyChildren.length == 0) {
        users = this.usersOriginal
      }
      this.desserts = users
      this.$forceUpdate()
    },
    updateItemsPerPage() {
      this.page = 1;
    },
    singleQrPrint(item, index) {
      console.log(item)
      let isTable = this.desserts.every(i => i.company && i.company.type === 'table')
      let column = isTable ? 4 : this.card.type == 2 ? 2 : 1

      let el = document.getElementById('qrTable');
      let trs = el.getElementsByTagName('tbody')
      let arr = trs[0].getElementsByTagName('tr')[index]

      let html = '<table>'
      let i = 1
      // arr.forEach((tr, index) => {
      let tr = arr

      if(index%column === 0) {
        i = 1
        html += '<tr>'
      }

      let tds = tr.getElementsByTagName('td')
      let tdss = document.getElementById('qrkodstampa')
      if(tds.length) {
        let id = this.card.visible_fields[0] == 1 ? tds[1].innerHTML : ''
        let name = (this.card.visible_fields[1] == 2 ? tds[2].innerHTML : '') + ' ' + (this.card.visible_fields[2] == 3 ? tds[3].innerHTML : '')
        let email = this.card.visible_fields[3] == 4 ? tds[4].innerHTML : ''

        let style2 = index%column === 0 ? 'border-left: 1px solid #f1f1f1; padding-right: 6px' : 'padding-right: 6px'
        let canvas = tdss.getElementsByTagName('svg')

        const serializer = new XMLSerializer();
        const svgString = serializer.serializeToString(canvas[0]);
        const encodedData = 'data:image/svg+xml,' + encodeURIComponent(svgString);
        html += '<td style="'+style2+'">'
        html += `<img src="${encodedData}" style="padding-left: ${this.card.marginTop}px;width: ${this.card.width*0.4}mm; height: ${this.card.width*0.4}mm"/>`
        if(isTable) html += `<div style="font-size: ${this.card.fontSize}px; text-align: center">${name}</div>`
        html += '</td>'

        let style = index%column === 0 ? 'border-right: 1px solid #f1f1f1;' : 'border-right: 1px solid #f1f1f1;'

        if(!isTable) {
          html += '<td style="'+style+'">'
          html += `<div style="margin-left: 10px;display: flex; flex-direction: column; height: ${this.card.height}mm; justify-content: center; width: ${this.card.width*0.6}mm;">`
          html += `<div style="height: ${this.card.marginTop === 'auto' ? (this.card.height*0.4 - 15) : (this.card.height - this.card.marginTop)}mm; line-height: 1.5">`
          html += `<div style="font-size: ${this.card.fontSize}px">${id}</div>`
          html += `<div style="font-size: ${this.card.fontSize}px">${name}</div>`
          html += `<div>${email}</div>`
          html += `</div>`
          html += `<div style="text-align: end"><span style="margin-right: 10px; color: #f1f1f1">ordera.app</span> <img src="./o-large.svg" width="30px" style="margin-bottom: -4px; margin-right: ${this.card.marginTop}px"/></div>`
          html += `</div>`
          html += '</td>'
        }
      }
      if(i === column) html += '</tr>'
      i++
      // })
      html += '</table>'

      html = [
        '<!DOCTYPE html>'+
        '<html>',
        '   <head>',
        '   <style>td{border-bottom: 1px solid #f1f1f1;}</style>',
        '   </head>',
        '   <body onload="window.print()" onafterprint="window.close()" style="font-family: Roboto, sans-serif; font-size: '+ (this.card.fontSize - 2) +'px!important;">',
        html,
        '   </body>',
        '</html>'
      ].join('')
      let win = window.open("",
          "",
          "left=0,top=0,toolbar=0,scrollbars=0,status=0"
      );
      win.document.open();
      win.document.write(html);
      win.document.close();

    },
    test() {
      let isTable = this.desserts.every(i => i.company && i.company.type === 'table')
      let column = isTable ? 4 : this.card.type == 2 ? 2 : 1

      let el = document.getElementById('qrTable');
      let trs = el.getElementsByTagName('tbody')
      let arr = trs[0].getElementsByTagName('tr')

      let html = '<table>'
      let i = 1
      arr.forEach((tr, index) => {

        if(index%column === 0) {
          i = 1
          html += '<tr>'
        }

        let tds = tr.getElementsByTagName('td')
          if(tds.length) {

            let id = this.card.visible_fields[0] == 1 ? tds[0].innerHTML : ''
            let name = (this.card.visible_fields[1] == 2 ? tds[1].innerHTML : '') + ' ' + (this.card.visible_fields[2] == 3 ? tds[2].innerHTML : '')
            let email = this.card.visible_fields[3] == 4 ? tds[3].innerHTML : ''

            let style2 = index%column === 0 ? 'border-left: 1px solid #f1f1f1; padding-right: 6px' : 'padding-right: 6px'
            let canvas = tds[5].getElementsByTagName('svg')

            const serializer = new XMLSerializer();
            const svgString = serializer.serializeToString(canvas[0]);
            const encodedData = 'data:image/svg+xml,' + encodeURIComponent(svgString);
            html += '<td style="'+style2+'">'
              html += `<img src="${encodedData}" style="padding-left: ${this.card.marginTop}px;width: ${this.card.width*0.4}mm; height: ${this.card.width*0.4}mm"/>`
              if(isTable) html += `<div style="font-size: ${this.card.fontSize}px; text-align: center">${name}</div>`
            html += '</td>'

            let style = index%column === 0 ? 'border-right: 1px solid #f1f1f1;' : 'border-right: 1px solid #f1f1f1;'

            if(!isTable) {
              html += '<td style="'+style+'">'
                html += `<div style="display: flex; flex-direction: column; height: ${this.card.height}mm; justify-content: center; width: ${this.card.width*0.6}mm">`
                  html += `<div style="height: ${this.card.marginTop === 'auto' ? (this.card.height*0.4 - 15) : (this.card.height - this.card.marginTop)}mm; line-height: 1.5">`
                    html += `<div style="font-size: ${this.card.fontSize}px">${id}</div>`
                    html += `<div style="font-size: ${this.card.fontSize}px">${name}</div>`
                    html += `<div>${email}</div>`
                  html += `</div>`
                  html += `<div style="text-align: end"><span style="margin-right: 10px; color: #f1f1f1">ordera.app</span> <img src="./o-large.svg" width="30px" style="margin-bottom: -4px;margin-right: ${this.card.marginTop}px"/></div>`
                html += `</div>`
              html += '</td>'
            }
          }
          if(i === column) html += '</tr>'
          i++
      })
      html += '</table>'

      html = [
        '<!DOCTYPE html>'+
        '<html>',
        '   <head>',
        '   <style>td{border-bottom: 1px solid #f1f1f1;}' +
        '    table { page-break-inside:auto }\n' +
        '    tr    { page-break-inside:avoid; page-break-after:auto }\n' +
        '    thead { display:table-header-group }\n' +
        '    tfoot { display:table-footer-group }</style>',
        '   </head>',
        '   <body onload="window.print()" onafterprint="window.close()" style="font-family: Roboto, sans-serif; font-size: '+ (this.card.fontSize - 2) +'px!important;">',
        html,
        '   </body>',
        '</html>'
      ].join('')
      let win = window.open("",
              "",
              "left=0,top=0,toolbar=0,scrollbars=0,status=0"
      );
      win.document.open();
      win.document.write(html);
      win.document.close();

    },
   /*  print(){
      let el = document.getElementById('qrTable');
      let newWin = window.open("");
      newWin.document.write(el.querySelector("table").outerHTML);
      newWin.print();
      newWin.close();
    }, */
    getQR(item) {
      axios.get(`/user/${item.id}/hash`).then(res => {
        return JSON.stringify({id:item.id,secure:res.data});
      })
    },
    initialize() {
      axios
        .get("/companies/" + this.id + "/users")
        .then((res) => {
          this.restaurantName = res.data.name;
          res.data.users.forEach((el, index)=>{
            // el.rb = index + 1

            if (typeof el.qrCode === 'object' && el.qrCode !== null) {
              el.qrCode = JSON.stringify(el.qrCode);
            }
            console.log(el.qrCode)
            this.desserts.push(el);
            this.usersOriginal.push(el);
            this.parentCompanies.filter(itm => {
              if (itm.id == res.data.id) {
                itm.users = res.data.users;
              }
            })
          })
        });
    },
    initializeRes() {
      axios
        .get("/restaurants/" + this.$route.params.res + "/users")
        .then((res) => {
          this.restaurantName = res.data.name;
          res.data.users.forEach((el, index)=>{
            // el.rb = index + 1
            this.desserts.push(el);
            this.usersOriginal.push(el);
          })
        });
    },
      initializeJela() {
        axios
          .get("/restaurants/" + this.$route.params.jela + "/foods")
          .then((res) => {
            this.restaurantName = res.data.name;
            res.data.foods.forEach((el, index)=>{
              // el.rb = index + 1
              this.desserts.push(el);
              this.usersOriginal.push(el);
            })
          });
      },
  },
};
</script>

<style>
  .qr-list .v-data-footer {
    padding-right: 115px!important;
  }
  @media (max-width: 599px) {
    header.dimensions {
      height: 220px !important;
    }
    header.dimensions > div {
      height: 210px !important;
    }
    .gornja_margina {
      margin-top: -10px;
      padding: 0;
      background: none;
    }
  }

  @page {
    size: A3;
    margin: 0;
  }
  @media print {
    html, body {
      width: 210mm;
      height: 297mm;
    }
  }
</style>
