<template>
  <v-row class="pa-3">
    <v-col cols="12">
      <p style="font-weight: 700;font-size: 25px;text-align: center">{{ $t('contracts') }}</p>
    </v-col>
<!--    <v-col cols="12" style="display: flex">-->
<!--      <v-switch-->
<!--        @click="switchTimeStatus('old')"-->
<!--        v-model="settings.old_time_changes"-->
<!--        inset></v-switch>-->
<!--      <p style="font-weight: 700;font-size: 19px">{{ $t('old_time_changes') }}</p>-->
<!--    </v-col>-->
    <v-col cols="12" sm="10">
<!--      <v-row v-if="settings.old_time_changes">-->
<!--        <v-col cols="12" sm="6">-->
<!--          <v-switch-->
<!--              @click="switchDays()"-->
<!--              v-model="settings.rok_dana"-->
<!--              inset-->
<!--              :label="$t('num_days_prior_order')"></v-switch>-->
<!--        </v-col>-->
<!--        <v-col cols="12" sm="6">-->
<!--          <div style="display: flex;">-->
<!--          <v-text-field-->
<!--              :disabled="! settings.rok_dana"-->
<!--              :prefix="$t('specific_word')"-->
<!--              :label="$t('num_days_past_due_order')"-->
<!--              v-model="settings.order_before">-->
<!--          </v-text-field>-->
<!--          <v-select style="margin-left: 10px"-->
<!--              :disabled="! settings.rok_dana"-->
<!--              :items="[-->
<!--                  {val: 0, name: 0},-->
<!--                  {val: 1, name: 1},-->
<!--                  {val: 2, name: 2},-->
<!--                  {val: 3, name: 3},-->
<!--                  {val: 4, name: 4},-->
<!--                  {val: 5, name: 5},-->
<!--                  {val: 6, name: 6},-->
<!--                  {val: 7, name: 7},-->
<!--                  {val: 8, name: 8},-->
<!--                  {val: 9, name: 9},-->
<!--                  {val: 10, name: 10},-->
<!--                  {val: 11, name: 11},-->
<!--                  {val: 12, name: 12},-->
<!--                  {val: 13, name: 13},-->
<!--                  {val: 14, name: 14},-->
<!--                  {val: 15, name: 15},-->
<!--                  {val: 16, name: 16},-->
<!--                  {val: 17, name: 17},-->
<!--                  {val: 18, name: 18},-->
<!--                  {val: 19, name: 19},-->
<!--                  {val: 20, name: 20},-->
<!--                  {val: 21, name: 21},-->
<!--                  {val: 22, name: 22},-->
<!--                  {val: 23, name: 23},-->
<!--                  {val: 24, name: 24},-->
<!--              ]"-->
<!--              item-value="val"-->
<!--              item-text="name"-->
<!--              v-model="settings.order_before_hours"-->
<!--              :label="$t('hours_to') +  ' 0 - 24'"-->
<!--              outlined-->
<!--          >-->
<!--          </v-select>-->
<!--  &lt;!&ndash;        <v-text-field style="margin-left: 10px"&ndash;&gt;-->
<!--  &lt;!&ndash;            :disabled="! settings.rok_dana"&ndash;&gt;-->
<!--  &lt;!&ndash;            :label="$t('hours_to') +  ' 0 - 24'"&ndash;&gt;-->
<!--  &lt;!&ndash;            v-model="settings.order_before_hours"&ndash;&gt;-->
<!--  &lt;!&ndash;        >&ndash;&gt;-->
<!--  &lt;!&ndash;        </v-text-field>&ndash;&gt;-->
<!--          </div>-->
<!--        </v-col>-->
<!--      </v-row>-->
<!--      <v-row v-if="settings.old_time_changes">-->
<!--        <v-col cols="12" sm="10">-->
<!--          <v-row>-->
<!--            <v-col cols="12" sm="7">-->
<!--              <v-switch-->
<!--                  @click="switchHours()"-->
<!--                  v-model="settings.rok_sati"-->
<!--                  inset-->
<!--                  :label="$t('day_hour_order_next_week')"-->
<!--              ></v-switch>-->
<!--            </v-col>-->
<!--            <v-col cols="12" sm="3">-->
<!--             <v-select-->
<!--                 :disabled="! settings.rok_sati"-->
<!--                 :items="days"-->
<!--                 item-value="val"-->
<!--                 v-model="settings.dan"-->
<!--                 :label="$t('select_day')"-->
<!--                 outlined-->
<!--             >-->
<!--             </v-select>-->
<!--            </v-col>-->
<!--            <v-col cols="12" sm="2">-->
<!--              <v-row>-->
<!--                <v-col cols="12" sm="12">-->
<!--                  <v-text-field-->
<!--                    :disabled="! settings.rok_sati"-->
<!--                    v-model="settings.broj_sati"-->
<!--                    outlined-->
<!--                    type="number"-->
<!--                    min=0-->
<!--                    max=24-->
<!--                    :label="$t('enter_hours')"-->
<!--                  ></v-text-field>-->
<!--                </v-col>-->
<!--              </v-row>-->
<!--            </v-col>-->
<!--          </v-row>-->
<!--        </v-col>-->
<!--      </v-row>-->
      <!-- NOVA VREMENSKA PODESAVANJA-->
      <v-row class="pt-0 mt-0 mb-2">
        <v-col v-if="! settings.new_time_changes" cols="12" style="font-size:13px;display: flex">
          <v-switch
            @click="switchTimeStatus('new')"
            v-model="settings.new_time_changes"
            inset
          ></v-switch>
          <p style="font-weight: 700;font-size: 19px">{{ $t('new_time_changes') }}</p>
        </v-col>
        <v-col cols="12">
          <p style="font-weight: 700;font-size: 19px">{{ $t('order_dead_line') }}</p>
        </v-col>
      </v-row>
      <v-col cols="12" v-if="settings.new_time_changes" style="margin-bottom: 30px;">
        <v-row class="px-3" style="border-bottom: 1px solid #cfcfcf;display: flex;align-items: center;margin-bottom: 0;">
          <v-col style="text-align: center" class="py-0" cols="2">
              <label style="display:block;height:100%;line-height: 4.3; margin-right: 5px;background: rgba(251,109,59, 0.10);border-radius: 5px;" for="">{{ $t('monday') }}</label>
<!--            <v-switch-->
<!--              @click="switchDays()"-->
<!--              v-model="settings.days.day.monday"-->
<!--              inset-->
<!--              :label="$t('monday')"-->
<!--              ></v-switch>-->
          </v-col>
          <v-col class="py-0" cols="2">
            <v-row style="margin-top: 10px;margin-right:5px">
                    <!--              <v-select class="velicina-labele"-->
                    <!--                :items="[-->
                    <!--                  {id: 1, name: $t('monday')},-->
                    <!--                  {id: 2, name: $t('tuesday')},-->
                    <!--                  {id: 3, name: $t('wednesday')},-->
                    <!--                  {id: 4, name: $t('thursday')},-->
                    <!--                  {id: 5, name: $t('friday')},-->
                    <!--                  {id: 6, name: $t('saturday')},-->
                    <!--                  {id: 7, name: $t('sunday')}]"-->
                    <!--                item-value="id"-->
                    <!--                item-text="name"-->
                    <!--                v-model="settings.days.monday.day_before_limit"-->
                    <!--                :label="$t('day_before_limit')"-->
                    <!--                outlined>-->
                    <!--              </v-select>-->
                <v-text-field :title="$t('day_before_limit')"
                  :label="$t('day_before_limit')"
                  type="number"
                  v-model.number="settings.days.monday.day_before_limit">
                </v-text-field>
              </v-row>
          </v-col>
          <v-col class="py-0 text-center" style="max-width: 2.3% !important;"><</v-col>
          <v-col style="background: rgba(251,109,59, 0.10);border-radius: 5px" class="py-0 px-8" cols="5">
            <v-row style="margin-top: 10px">
              <v-select style="width: 20%"
                :items="delivery_from_hour"
                item-value="val"
                v-model="settings.days.monday.time.time_hour"
                :label="$t('starting_from_h')"
                outlined
              >
              </v-select>
              <v-select style="margin-left: 10px;width: 20%"
                :items="delivery_to_min"
                item-value="val"
                v-model="settings.days.monday.time.time_minut"
                :label="$t('up_to_min')"
                outlined
                >
              </v-select>
            </v-row>
          </v-col>
          <v-col class="py-0 text-center" style="max-width: 2.3% !important;">></v-col>
          <v-col class="py-0" cols="2">
            <v-row style="margin-top: 10px;margin-left:0">
<!--              <v-select class="velicina-labele"-->
<!--              :items="[-->
<!--                {id: 1, name: $t('monday')},-->
<!--                {id: 2, name: $t('tuesday')},-->
<!--                {id: 3, name: $t('wednesday')},-->
<!--                {id: 4, name: $t('thursday')},-->
<!--                {id: 5, name: $t('friday')},-->
<!--                {id: 6, name: $t('saturday')},-->
<!--                {id: 7, name: $t('sunday')}]"-->
<!--              item-value="id"-->
<!--              item-text="name"-->
<!--              v-model="settings.days.monday.day_after_limit"-->
<!--              :label="$t('day_after_limit')"-->
<!--              outlined>-->
<!--            </v-select>-->
              <v-text-field :title="$t('day_after_limit')"
                :label="$t('day_after_limit')"
                type="number"
                v-model.number="settings.days.monday.day_after_limit">
              </v-text-field>
            </v-row>
        </v-col>
<!--          <v-col class="py-0" cols="2">-->
<!--            <v-row style="margin-top: 10px;margin-left:0">-->
<!--              <v-select class="velicina-labele"-->
<!--              :items="[-->
<!--                {id: 1, name: $t('monday')},-->
<!--                {id: 2, name: $t('tuesday')},-->
<!--                {id: 3, name: $t('wednesday')},-->
<!--                {id: 4, name: $t('thursday')},-->
<!--                {id: 5, name: $t('friday')},-->
<!--                {id: 6, name: $t('saturday')},-->
<!--                {id: 7, name: $t('sunday')}]"-->
<!--              item-value="id"-->
<!--              item-text="id"-->
<!--              v-model="settings.days.monday.change_week"-->
<!--              :label="$t('change_time')"-->
<!--              outlined>-->
<!--            </v-select>-->
<!--            </v-row>-->
<!--          </v-col>-->
        </v-row>
        <v-row class="px-3" style="border-bottom: 1px solid #cfcfcf;display: flex;align-items: center;margin-bottom: 0">
          <v-col style="text-align: center" class="py-0" cols="2">
            <label style="display:block;height:100%;line-height: 4.3; margin-right: 5px;background: rgba(251,109,59, 0.10);border-radius: 5px;" for="">{{ $t('tuesday') }}</label>
<!--            <v-switch-->
<!--                    @click="switchDays()"-->
<!--                    v-model="settings.days.day.tuesday"-->
<!--                    inset-->
<!--                    :label="$t('tuesday')"-->
<!--            ></v-switch>-->
          </v-col>
          <v-col class="py-0" cols="2">
                <v-row style="margin-top: 10px;margin-right:5px">
                    <!--              <v-select class="velicina-labele"-->
                    <!--                :items="[-->
                    <!--                {id: 1, name: $t('monday')},-->
                    <!--                {id: 2, name: $t('tuesday')},-->
                    <!--                {id: 3, name: $t('wednesday')},-->
                    <!--                {id: 4, name: $t('thursday')},-->
                    <!--                {id: 5, name: $t('friday')},-->
                    <!--                {id: 6, name: $t('saturday')},-->
                    <!--                {id: 7, name: $t('sunday')}]"-->
                    <!--                item-value="id"-->
                    <!--                item-text="name"-->
                    <!--                v-model="settings.days.tuesday.day_before_limit"-->
                    <!--                :label="$t('day_before_limit')"-->
                    <!--                outlined>-->
                    <!--              </v-select>-->
                    <v-text-field :title="$t('day_before_limit')"
                            :label="$t('day_before_limit')"
                            type="number"
                            v-model.number="settings.days.tuesday.day_before_limit">
                    </v-text-field>
                </v-row>
            </v-col>
          <v-col class="py-0 text-center" style="max-width: 2.3% !important;"><</v-col>
          <v-col class="py-0 px-8" style="background: rgba(251,109,59, 0.10);border-radius: 5px" cols="5">
            <v-row style="margin-top: 10px">
              <v-select style="width: 20%"
                :items="delivery_from_hour"
                item-value="val"
                v-model="settings.days.tuesday.time.time_hour"
                :label="$t('starting_from_h')"
                outlined
              >
              </v-select>
              <v-select style="margin-left: 10px;width: 20%"
                  :items="delivery_to_min"
                  item-value="val"
                  v-model="settings.days.tuesday.time.time_minut"
                  :label="$t('up_to_min')"
                  outlined
                >
                </v-select>
            </v-row>
          </v-col>
          <v-col class="py-0 text-center" style="max-width: 2.3% !important;">> </v-col>
          <v-col class="py-0" cols="2">
            <v-row style="margin-top: 10px;margin-left:0">
<!--              <v-select class="velicina-labele"-->
<!--                :items="[-->
<!--                {id: 1, name: $t('monday')},-->
<!--                {id: 2, name: $t('tuesday')},-->
<!--                {id: 3, name: $t('wednesday')},-->
<!--                {id: 4, name: $t('thursday')},-->
<!--                {id: 5, name: $t('friday')},-->
<!--                {id: 6, name: $t('saturday')},-->
<!--                {id: 7, name: $t('sunday')}]"-->
<!--                item-value="id"-->
<!--                item-text="name"-->
<!--                v-model="settings.days.tuesday.day_after_limit"-->
<!--                :label="$t('day_after_limit')"-->
<!--                outlined>-->
<!--              </v-select>-->
                <v-text-field :title="$t('day_after_limit')"
                  :label="$t('day_after_limit')"
                  type="number"
                  v-model.number="settings.days.tuesday.day_after_limit">
                </v-text-field>
            </v-row>
          </v-col>
<!--          <v-col class="py-0" cols="2">-->
<!--            <v-row style="margin-top: 10px;margin-left:0">-->
<!--              <v-select class="velicina-labele"-->
<!--                :items="[-->
<!--                {id: 1, name: $t('monday')},-->
<!--                {id: 2, name: $t('tuesday')},-->
<!--                {id: 3, name: $t('wednesday')},-->
<!--                {id: 4, name: $t('thursday')},-->
<!--                {id: 5, name: $t('friday')},-->
<!--                {id: 6, name: $t('saturday')},-->
<!--                {id: 7, name: $t('sunday')}]"-->
<!--                item-value="id"-->
<!--                item-text="id"-->
<!--                v-model="settings.days.tuesday.change_week"-->
<!--                :label="$t('change_time')"-->
<!--                outlined>-->
<!--              </v-select>-->
<!--            </v-row>-->
<!--            </v-col>-->
        </v-row>
        <v-row class="px-3" style="border-bottom: 1px solid #cfcfcf;display: flex;align-items: center;margin-bottom: 0">
          <v-col class="py-0 text-center" cols="2">
            <label style="display:block;height:100%;line-height: 4.3; margin-right: 5px;background: rgba(251,109,59, 0.10);border-radius: 5px;" for="">{{ $t('wednesday') }}</label>
<!--            <v-switch-->
<!--                    @click="switchDays()"-->
<!--                    v-model="settings.days.day.wednesday"-->
<!--                    inset-->
<!--                    :label="$t('wednesday')"-->
<!--            ></v-switch>-->
          </v-col>
          <v-col class="py-0" cols="2">
                <v-row style="margin-top: 10px;margin-right:5px">
                    <!--              <v-select class="velicina-labele"-->
                    <!--                :items="[-->
                    <!--                {id: 1, name: $t('monday')},-->
                    <!--                {id: 2, name: $t('tuesday')},-->
                    <!--                {id: 3, name: $t('wednesday')},-->
                    <!--                {id: 4, name: $t('thursday')},-->
                    <!--                {id: 5, name: $t('friday')},-->
                    <!--                {id: 6, name: $t('saturday')},-->
                    <!--                {id: 7, name: $t('sunday')}]"-->
                    <!--                item-value="id"-->
                    <!--                item-text="name"-->
                    <!--                v-model="settings.days.wednesday.day_before_limit"-->
                    <!--                :label="$t('day_before_limit')"-->
                    <!--                outlined>-->
                    <!--              </v-select>-->
                    <v-text-field :title="$t('day_before_limit')"
                            :label="$t('day_before_limit')"
                            type="number"
                            v-model.number="settings.days.wednesday.day_before_limit">
                    </v-text-field>
                </v-row>
            </v-col>
          <v-col class="py-0 text-center" style="max-width: 2.3% !important;"><</v-col>
          <v-col class="py-0 px-8" style="background: rgba(251,109,59, 0.10);border-radius: 5px" cols="5">
            <v-row style="margin-top: 10px">
              <v-select style="width: 20%"
                        :items="delivery_from_hour"
                        item-value="val"
                        v-model="settings.days.wednesday.time.time_hour"
                        :label="$t('starting_from_h')"
                        outlined
              >
              </v-select>
              <v-select style="margin-left: 10px;width: 20%"
                          :items="delivery_to_min"
                          item-value="val"
                          v-model="settings.days.wednesday.time.time_minut"
                          :label="$t('up_to_min')"
                          outlined
                >
                </v-select>
            </v-row>
          </v-col>
          <v-col class="py-0 text-center" style="max-width: 2.3% !important;">> </v-col>
          <v-col class="py-0" cols="2">
            <v-row style="margin-top: 10px;margin-left:0">
<!--              <v-select class="velicina-labele"-->
<!--                :items="[-->
<!--                {id: 1, name: $t('monday')},-->
<!--                {id: 2, name: $t('tuesday')},-->
<!--                {id: 3, name: $t('wednesday')},-->
<!--                {id: 4, name: $t('thursday')},-->
<!--                {id: 5, name: $t('friday')},-->
<!--                {id: 6, name: $t('saturday')},-->
<!--                {id: 7, name: $t('sunday')}]"-->
<!--                item-value="id"-->
<!--                item-text="name"-->
<!--                v-model="settings.days.wednesday.day_after_limit"-->
<!--                :label="$t('day_after_limit')"-->
<!--                outlined>-->
<!--              </v-select>-->
                <v-text-field :title="$t('day_after_limit')"
                        :label="$t('day_after_limit')"
                        type="number"
                        v-model.number="settings.days.wednesday.day_after_limit">
                </v-text-field>
            </v-row>
          </v-col>
<!--          <v-col class="py-0" cols="2">-->
<!--            <v-row style="margin-top: 10px;margin-left:0">-->
<!--              <v-select class="velicina-labele"-->
<!--                :items="[-->
<!--                {id: 1, name: $t('monday')},-->
<!--                {id: 2, name: $t('tuesday')},-->
<!--                {id: 3, name: $t('wednesday')},-->
<!--                {id: 4, name: $t('thursday')},-->
<!--                {id: 5, name: $t('friday')},-->
<!--                {id: 6, name: $t('saturday')},-->
<!--                {id: 7, name: $t('sunday')}]"-->
<!--                item-value="id"-->
<!--                item-text="id"-->
<!--                v-model="settings.days.wednesday.change_week"-->
<!--                :label="$t('change_time')"-->
<!--                outlined>-->
<!--              </v-select>-->
<!--            </v-row>-->
<!--          </v-col>-->
        </v-row>
        <v-row class="px-3" style="border-bottom: 1px solid #cfcfcf;display: flex;align-items: center;margin-bottom: 0">
          <v-col class="py-0 text-center" cols="2">
            <label style="display:block;height:100%;line-height: 4.3; margin-right: 5px;background: rgba(251,109,59, 0.10);border-radius: 5px;" for="">{{ $t('thursday')}}</label>
<!--            <v-switch-->
<!--                    @click="switchDays()"-->
<!--                    v-model="settings.days.day.thursday"-->
<!--                    inset-->
<!--                    :label="$t('thursday')"-->
<!--            ></v-switch>-->
          </v-col>
          <v-col class="py-0" cols="2">
                <v-row style="margin-top: 10px;margin-right:5px">
                    <!--              <v-select class="velicina-labele"-->
                    <!--                :items="[-->
                    <!--                {id: 1, name: $t('monday')},-->
                    <!--                {id: 2, name: $t('tuesday')},-->
                    <!--                {id: 3, name: $t('wednesday')},-->
                    <!--                {id: 4, name: $t('thursday')},-->
                    <!--                {id: 5, name: $t('friday')},-->
                    <!--                {id: 6, name: $t('saturday')},-->
                    <!--                {id: 7, name: $t('sunday')}]"-->
                    <!--                item-value="id"-->
                    <!--                item-text="name"-->
                    <!--                v-model="settings.days.thursday.day_before_limit"-->
                    <!--                :label="$t('day_before_limit')"-->
                    <!--                outlined>-->
                    <!--              </v-select>-->
                    <v-text-field :title="$t('day_before_limit')"
                            :label="$t('day_before_limit')"
                            type="number"
                            v-model.number="settings.days.thursday.day_before_limit">
                    </v-text-field>
                </v-row>
            </v-col>
          <v-col class="py-0 text-center" style="max-width: 2.3% !important;"><</v-col>
          <v-col class="py-0 px-8" style="background: rgba(251,109,59, 0.10);border-radius: 5px" cols="5">
              <v-row style="margin-top: 10px">
                <v-select style="width: 20%"
                          :items="delivery_from_hour"
                          item-value="val"
                          v-model="settings.days.thursday.time.time_hour"
                          :label="$t('starting_from_h')"
                          outlined
                >
                </v-select>
                <v-select style="margin-left: 10px;width: 20%"
                            :items="delivery_to_min"
                            item-value="val"
                            v-model="settings.days.thursday.time.time_minut"
                            :label="$t('up_to_min')"
                            outlined
                  >
                  </v-select>
              </v-row>
          </v-col>
          <v-col class="py-0 text-center" style="max-width: 2.3% !important;">> </v-col>
          <v-col class="py-0" cols="2">
            <v-row style="margin-top: 10px;margin-left:0">
<!--              <v-select class="velicina-labele"-->
<!--                :items="[-->
<!--                {id: 1, name: $t('monday')},-->
<!--                {id: 2, name: $t('tuesday')},-->
<!--                {id: 3, name: $t('wednesday')},-->
<!--                {id: 4, name: $t('thursday')},-->
<!--                {id: 5, name: $t('friday')},-->
<!--                {id: 6, name: $t('saturday')},-->
<!--                {id: 7, name: $t('sunday')}]"-->
<!--                item-value="id"-->
<!--                item-text="name"-->
<!--                v-model="settings.days.thursday.day_after_limit"-->
<!--                :label="$t('day_after_limit')"-->
<!--                outlined>-->
<!--              </v-select>-->
                <v-text-field :title="$t('day_after_limit')"
                        :label="$t('day_after_limit')"
                        type="number"
                        v-model.number="settings.days.thursday.day_after_limit">
                </v-text-field>
            </v-row>
          </v-col>
<!--          <v-col class="py-0" cols="2">-->
<!--            <v-row style="margin-top: 10px;margin-left:0">-->
<!--              <v-select class="velicina-labele"-->
<!--                :items="[-->
<!--                {id: 1, name: $t('monday')},-->
<!--                {id: 2, name: $t('tuesday')},-->
<!--                {id: 3, name: $t('wednesday')},-->
<!--                {id: 4, name: $t('thursday')},-->
<!--                {id: 5, name: $t('friday')},-->
<!--                {id: 6, name: $t('saturday')},-->
<!--                {id: 7, name: $t('sunday')}]"-->
<!--                item-value="id"-->
<!--                item-text="id"-->
<!--                v-model="settings.days.thursday.change_week"-->
<!--                :label="$t('change_time')"-->
<!--                outlined>-->
<!--              </v-select>-->
<!--            </v-row>-->
<!--          </v-col>-->
        </v-row>
        <v-row class="px-3" style="border-bottom: 1px solid #cfcfcf;display: flex;align-items: center;margin-bottom: 0">
          <v-col class="py-0 text-center" cols="2">
            <label style="display:block;height:100%;line-height: 4.3; margin-right: 5px;background: rgba(251,109,59, 0.10);border-radius: 5px;" for="">{{ $t('friday') }}</label>
<!--            <v-switch-->
<!--                    @click="switchDays()"-->
<!--                    v-model="settings.days.day.friday"-->
<!--                    inset-->
<!--                    :label="$t('friday')"-->
<!--            ></v-switch>-->
          </v-col>
          <v-col class="py-0" cols="2">
                <v-row style="margin-top: 10px;margin-right:5px">
                    <!--                  <v-select class="velicina-labele"-->
                    <!--                            :items="[-->
                    <!--                            {id: 1, name: $t('monday')},-->
                    <!--                            {id: 2, name: $t('tuesday')},-->
                    <!--                            {id: 3, name: $t('wednesday')},-->
                    <!--                            {id: 4, name: $t('thursday')},-->
                    <!--                            {id: 5, name: $t('friday')},-->
                    <!--                            {id: 6, name: $t('saturday')},-->
                    <!--                            {id: 7, name: $t('sunday')}]"-->
                    <!--                            item-value="id"-->
                    <!--                            item-text="name"-->
                    <!--                            v-model="settings.days.friday.day_before_limit"-->
                    <!--                            :label="$t('day_before_limit')"-->
                    <!--                            outlined>-->
                    <!--                  </v-select>-->
                    <v-text-field :title="$t('day_before_limit')"
                            :label="$t('day_before_limit')"
                            type="number"
                            v-model.number="settings.days.friday.day_before_limit">
                    </v-text-field>
                </v-row>
            </v-col>
          <v-col class="py-0 text-center" style="max-width: 2.3% !important;"><</v-col>
          <v-col class="py-0 px-8" style="background: rgba(251,109,59, 0.10);border-radius: 5px" cols="5">
            <v-row style="margin-top: 10px">
              <v-select style="width: 20%"
                        :items="delivery_from_hour"
                        item-value="val"
                        v-model="settings.days.friday.time.time_hour"
                        :label="$t('starting_from_h')"
                        outlined
              >
              </v-select>
              <v-select style="margin-left: 10px;width: 20%"
                          :items="delivery_to_min"
                          item-value="val"
                          v-model="settings.days.friday.time.time_minut"
                          :label="$t('up_to_min')"
                          outlined
                >
                </v-select>
            </v-row>
          </v-col>
          <v-col class="py-0 text-center" style="max-width: 2.3% !important;">> </v-col>
          <v-col class="py-0" cols="2">
            <v-row style="margin-top: 10px;margin-left:0">
<!--              <v-select class="velicina-labele"-->
<!--                :items="[-->
<!--                {id: 1, name: $t('monday')},-->
<!--                {id: 2, name: $t('tuesday')},-->
<!--                {id: 3, name: $t('wednesday')},-->
<!--                {id: 4, name: $t('thursday')},-->
<!--                {id: 5, name: $t('friday')},-->
<!--                {id: 6, name: $t('saturday')},-->
<!--                {id: 7, name: $t('sunday')}]"-->
<!--                item-value="id"-->
<!--                item-text="name"-->
<!--                v-model="settings.days.friday.day_after_limit"-->
<!--                :label="$t('day_after_limit')"-->
<!--                outlined>-->
<!--              </v-select>-->
                <v-text-field :title="$t('day_after_limit')"
                        :label="$t('day_after_limit')"
                        type="number"
                        v-model.number="settings.days.friday.day_after_limit">
                </v-text-field>
            </v-row>
          </v-col>
<!--          <v-col class="py-0" cols="2">-->
<!--            <v-row style="margin-top: 10px;margin-left:0">-->
<!--              <v-select class="velicina-labele"-->
<!--                :items="[-->
<!--                {id: 1, name: $t('monday')},-->
<!--                {id: 2, name: $t('tuesday')},-->
<!--                {id: 3, name: $t('wednesday')},-->
<!--                {id: 4, name: $t('thursday')},-->
<!--                {id: 5, name: $t('friday')},-->
<!--                {id: 6, name: $t('saturday')},-->
<!--                {id: 7, name: $t('sunday')}]"-->
<!--                item-value="id"-->
<!--                item-text="id"-->
<!--                v-model="settings.days.friday.change_week"-->
<!--                :label="$t('change_time')"-->
<!--                outlined>-->
<!--              </v-select>-->
<!--            </v-row>-->
<!--          </v-col>-->
        </v-row>
        <v-row class="px-3" style="border-bottom: 1px solid #cfcfcf;display: flex;align-items: center;margin-bottom: 0">
          <v-col class="py-0 text-center" cols="2">
            <label style="display:block;height:100%;line-height: 4.3; margin-right: 5px;background: rgba(251,109,59, 0.10);border-radius: 5px;" for="">{{ $t('saturday')}}</label>
<!--            <v-switch-->
<!--                    @click="switchDays()"-->
<!--                    v-model="settings.days.day.saturday"-->
<!--                    inset-->
<!--                    :label="$t('saturday')"-->
<!--            ></v-switch>-->
          </v-col>
          <v-col class="py-0" cols="2">
                <v-row style="margin-top: 10px;margin-right:5px">
                    <!--                <v-select class="velicina-labele"-->
                    <!--                  :items="[-->
                    <!--                  {id: 1, name: $t('monday')},-->
                    <!--                  {id: 2, name: $t('tuesday')},-->
                    <!--                  {id: 3, name: $t('wednesday')},-->
                    <!--                  {id: 4, name: $t('thursday')},-->
                    <!--                  {id: 5, name: $t('friday')},-->
                    <!--                  {id: 6, name: $t('saturday')},-->
                    <!--                  {id: 7, name: $t('sunday')}]"-->
                    <!--                  item-value="id"-->
                    <!--                  item-text="name"-->
                    <!--                  v-model="settings.days.saturday.day_before_limit"-->
                    <!--                  :label="$t('day_before_limit')"-->
                    <!--                  outlined>-->
                    <!--                </v-select>-->
                    <v-text-field :title="$t('day_before_limit')"
                            :label="$t('day_before_limit')"
                            type="number"
                            v-model.number="settings.days.saturday.day_before_limit">
                    </v-text-field>
                </v-row>
          </v-col>
          <v-col class="py-0 text-center" style="max-width: 2.3% !important;"><</v-col>
          <v-col class="py-0 px-8" style="background: rgba(251,109,59, 0.10);border-radius: 5px" cols="5">
          <v-row style="margin-top: 10px">
              <v-select style="width: 20%"
                        :items="delivery_from_hour"
                        item-value="val"
                        v-model="settings.days.saturday.time.time_hour"
                        :label="$t('starting_from_h')"
                        outlined
              >
              </v-select>
              <v-select style="margin-left: 10px;width: 20%"
                          :items="delivery_to_min"
                          item-value="val"
                          v-model="settings.days.saturday.time.time_minut"
                          :label="$t('up_to_min')"
                          outlined
                >
                </v-select>
            </v-row>
          </v-col>
          <v-col class="py-0 text-center" style="max-width: 2.3% !important;">> </v-col>
          <v-col class="py-0" cols="2">
            <v-row style="margin-top: 10px;margin-left:0">
<!--              <v-select class="velicina-labele"-->
<!--                :items="[-->
<!--                {id: 1, name: $t('monday')},-->
<!--                {id: 2, name: $t('tuesday')},-->
<!--                {id: 3, name: $t('wednesday')},-->
<!--                {id: 4, name: $t('thursday')},-->
<!--                {id: 5, name: $t('friday')},-->
<!--                {id: 6, name: $t('saturday')},-->
<!--                {id: 7, name: $t('sunday')}]"-->
<!--                item-value="id"-->
<!--                item-text="name"-->
<!--                v-model="settings.days.saturday.day_after_limit"-->
<!--                :label="$t('day_after_limit')"-->
<!--                outlined>-->
<!--              </v-select>-->
                <v-text-field :title="$t('day_after_limit')"
                        :label="$t('day_after_limit')"
                        type="number"
                        v-model.number="settings.days.saturday.day_after_limit">
                </v-text-field>
            </v-row>
          </v-col>
<!--          <v-col class="py-0" cols="2">-->
<!--            <v-row style="margin-top: 10px;margin-left:0">-->
<!--              <v-select class="velicina-labele"-->
<!--                :items="[-->
<!--                {id: 1, name: $t('monday')},-->
<!--                {id: 2, name: $t('tuesday')},-->
<!--                {id: 3, name: $t('wednesday')},-->
<!--                {id: 4, name: $t('thursday')},-->
<!--                {id: 5, name: $t('friday')},-->
<!--                {id: 6, name: $t('saturday')},-->
<!--                {id: 7, name: $t('sunday')}]"-->
<!--                item-value="id"-->
<!--                item-text="id"-->
<!--                v-model="settings.days.saturday.change_week"-->
<!--                :label="$t('change_time')"-->
<!--                outlined>-->
<!--              </v-select>-->
<!--            </v-row>-->
<!--          </v-col>-->
        </v-row>
        <v-row class="px-3" style="border-bottom: 1px solid #cfcfcf;display: flex;align-items: center;margin-bottom: 0">
          <v-col class="py-0 text-center" cols="2" >
            <label style="display:block;height:100%;line-height: 4.3; margin-right: 5px;background: rgba(251,109,59, 0.10);border-radius: 5px;" for="">{{ $t('sunday')}}</label>
<!--            <v-switch-->
<!--                    @click="switchDays()"-->
<!--                    v-model="settings.days.day.sunday"-->
<!--                    inset-->
<!--                    :label="$t('sunday')"-->
<!--            ></v-switch>-->
          </v-col>
          <v-col class="py-0" cols="2">
                <v-row style="margin-top: 10px;margin-right:5px">
                    <!--              <v-select class="velicina-labele"-->
                    <!--                :items="[-->
                    <!--                {id: 1, name: $t('monday')},-->
                    <!--                {id: 2, name: $t('tuesday')},-->
                    <!--                {id: 3, name: $t('wednesday')},-->
                    <!--                {id: 4, name: $t('thursday')},-->
                    <!--                {id: 5, name: $t('friday')},-->
                    <!--                {id: 6, name: $t('saturday')},-->
                    <!--                {id: 7, name: $t('sunday')}]"-->
                    <!--                item-value="id"-->
                    <!--                item-text="name"-->
                    <!--                v-model="settings.days.sunday.day_before_limit"-->
                    <!--                :label="$t('day_before_limit')"-->
                    <!--                outlined>-->
                    <!--              </v-select>-->
                    <v-text-field :title="$t('day_before_limit')"
                            :label="$t('day_before_limit')"
                            type="number"
                            v-model.number="settings.days.sunday.day_before_limit">
                    </v-text-field>
                </v-row>
            </v-col>
          <v-col class="py-0 text-center" style="max-width: 2.3% !important;"><</v-col>
          <v-col class="py-0 px-8" style="background: rgba(251,109,59, 0.10);border-radius: 5px" cols="5">
            <v-row style="margin-top: 10px">
              <v-select style="width: 20%" class="velicina-labele"
                        :items="delivery_from_hour"
                        item-value="val"
                        v-model="settings.days.sunday.time.time_hour"
                        :label="$t('starting_from_h')"
                        outlined
              >
              </v-select>
              <v-select style="margin-left: 10px;width: 20%" class="velicina-labele"
                          :items="delivery_to_min"
                          item-value="val"
                          v-model="settings.days.sunday.time.time_minut"
                          :label="$t('up_to_min')"
                          outlined
                >
                </v-select>
            </v-row>
          </v-col>
          <v-col class="py-0 text-center" style="max-width: 2.3% !important;">> </v-col>
          <v-col class="py-0" cols="2">
            <v-row style="margin-top: 10px;margin-left:0">
<!--              <v-select class="velicina-labele"-->
<!--                :items="[-->
<!--                {id: 1, name: $t('monday')},-->
<!--                {id: 2, name: $t('tuesday')},-->
<!--                {id: 3, name: $t('wednesday')},-->
<!--                {id: 4, name: $t('thursday')},-->
<!--                {id: 5, name: $t('friday')},-->
<!--                {id: 6, name: $t('saturday')},-->
<!--                {id: 7, name: $t('sunday')}]"-->
<!--                item-value="id"-->
<!--                item-text="name"-->
<!--                v-model="settings.days.sunday.day_after_limit"-->
<!--                :label="$t('day_after_limit')"-->
<!--                outlined>-->
<!--              </v-select>-->
                <v-text-field :title="$t('day_after_limit')"
                        :label="$t('day_after_limit')"
                        type="number"
                        v-model.number="settings.days.sunday.day_after_limit">
                </v-text-field>
            </v-row>
          </v-col>
<!--          <v-col class="py-0" cols="2">-->
<!--            <v-row style="margin-top: 10px;margin-left:0">-->
<!--              <v-select class="velicina-labele"-->
<!--                :items="[-->
<!--                {id: 1, name: $t('monday')},-->
<!--                {id: 2, name: $t('tuesday')},-->
<!--                {id: 3, name: $t('wednesday')},-->
<!--                {id: 4, name: $t('thursday')},-->
<!--                {id: 5, name: $t('friday')},-->
<!--                {id: 6, name: $t('saturday')},-->
<!--                {id: 7, name: $t('sunday')}]"-->
<!--                item-value="id"-->
<!--                item-text="id"-->
<!--                v-model="settings.days.sunday.change_week"-->
<!--                :label="$t('change_time')"-->
<!--                outlined>-->
<!--              </v-select>-->
<!--            </v-row>-->
<!--          </v-col>-->
        </v-row>
      </v-col>
      <!-- KRAJ VREMENSKA PODESAVANJA-->
      <v-row>
      <v-col cols="12" sm="9">
        <v-switch :title="'Izabranim firmama i radnicima omoguci narucivanje mimo vremenskog limita'"
            v-model="settings.no_time_limit"
            inset
            :label="$t('no_time_limit')"
        ></v-switch>
      </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="9">
          <v-switch
              v-model="settings.confirm_delivery"
              inset
              :label="$t('automatic_delivery_no_confirm')"
          ></v-switch>
        </v-col>
        <v-col cols="12" sm="3">
          <v-text-field
              v-model="settings.timer"
              outlined
              dense
              :label="$t('next_delivery_time_sec')"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" sm="12">
      <v-switch
          v-model="settings.izvestaj"
          inset
          :label="$t('delivery_report_available_for_users')"
      ></v-switch>
    </v-col>
    <v-col cols="12" sm="12">
      <v-switch
          v-model="settings.worker"
          inset
          :label="$t('enable_meal_pickup_another_branch')"
      ></v-switch>
    </v-col>
    <v-col cols="12" sm="12">
      <v-switch
          v-model="settings.can_reserve"
          inset
          :label="$t('enable_spare_meal_belated_user')"
      ></v-switch>
    </v-col>

  <v-col cols="12">
    <p style="font-weight: 700;font-size: 25px;text-align: center">{{ $t('guest_settings')}}</p>
  </v-col>
  <!--  Narucivanje bez ugovora PODESAVANJE ZA GOSTA-->

  <v-row class="okolo">
    <v-col lg="6" sm="12" class="mb-0 pb-0">
      <v-switch style="margin-bottom: -20px"
          v-model="settings.lice_bez_ugovora"
          inset
          :label="$t('ordering_persons_without_contract') + ' (' + translate('конзумација у ресторану, преузми сам, испорука на адресу') + ')'"
      ></v-switch>
    </v-col>
  </v-row>
  <section v-if="settings.lice_bez_ugovora">
    <v-row class="okolo">
      <v-col lg="4" sm="12" class="mb-0 pb-0">
        <v-switch style="margin-bottom: -20px"
            v-model="settings.guest.meal_in_restaurant"
            inset
            :label="$t('meal_in_restaurant')"
        ></v-switch>
      </v-col>
    </v-row>
    <v-row class="okolo">
      <v-col lg="4" sm="12" class="mb-0 pb-0">
        <v-switch
            v-model="settings.guest.personal_takeover"
            inset
            :label="$t('personal_takeover')"
        ></v-switch>
      </v-col>
    </v-row>
    <v-col style="margin-top: 10px" cols="12" sm="12">
      <v-row class="okolo" style="align-content: center;margin-top: 20px">
        <v-switch id="restoran-obavlja-isporuku"
            @click="setDelivery()"
            v-model="settings.guest.restoran_delivery"
            inset
            :label="$t('restaurant_delivers')"
        ></v-switch>
        <div v-if="settings.guest.restoran_delivery" style="width: 65%;margin-left: 15px">
          <v-row class="" v-if="settings.guest.restoran_delivery">
          <v-col cols="12" sm="6">
            <v-text-field
                v-model="settings.guest.delivery_price"
                outlined
                dense
                :label="$t('delivery_price')"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
                v-model="settings.guest.minimal_price"
                outlined
                type="int"
                dense
                :label="$t('minimal_price')"
            ></v-text-field>
          </v-col>
        </v-row>
        </div>
      </v-row>
    </v-col>
    <v-col style="margin-top: -30px" cols="12" sm="12">
      <v-row class="okolo" style="align-content: center">
        <v-switch id="slanje-postom"
                  @click="setDelivery()"
                  v-model="settings.guest.post_delivery"
                  inset
                  :label="$t('post_delivery')"
        ></v-switch>
        <div v-if="settings.guest.post_delivery" style="width: 100%;margin-left: 15px">
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                  v-model="settings.guest.post_price"
                  outlined
                  dense
                  :label="$t('delivery_price_post')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                  v-model="settings.guest.post_minimal_price"
                  outlined
                  dense
                  :label="$t('minimal_price_post')"
              ></v-text-field>
            </v-col>
          </v-row>
        </div>
      </v-row>
    </v-col>
    <v-col style="margin-top: -30px" cols="12" sm="9">
      <v-textarea style="margin-top: 0"
                v-model="settings.guest.post_description"
                inset
                :label="$t('post_description')"
      ></v-textarea>
    </v-col>

<!--    <v-col cols="12" sm="12">-->
<!--      <v-row>-->
<!--        <v-col cols="12" sm="4">ff-->
<!--          <v-switch-->
<!--              @click="switchDaysGuest()"-->
<!--              v-model="settings.guest.rok_dana"-->
<!--              inset-->
<!--              :label="$t('num_days_prior_order')"-->
<!--          ></v-switch>-->
<!--        </v-col>-->
<!--        <v-col cols="12" sm="4">-->
<!--          <v-text-field-->
<!--              :disabled="! settings.guest.rok_dana"-->
<!--              :prefix="translate('ДАН(A)')"-->
<!--              :label="$t('num_days_past_due_order')"-->
<!--              v-model="settings.guest.order_before"-->
<!--          >-->
<!--          </v-text-field>-->
<!--        </v-col>-->
<!--      </v-row>-->
<!--      <v-row>-->
<!--        <v-col cols="12" sm="12">-->
<!--          <v-row>-->
<!--            <v-col cols="12" sm="4">-->
<!--              <v-switch-->
<!--                @click="switchHoursGuest()"-->
<!--                v-model="settings.guest.rok_sati"-->
<!--                inset-->
<!--                :label="$t('day_hour_order_next_week')"-->
<!--              ></v-switch>-->
<!--            </v-col>-->
<!--            <v-col cols="12" sm="3">-->
<!--              <v-select-->
<!--                :disabled="! settings.guest.rok_sati"-->
<!--                :items="[{id: 1, name: $t('monday')},-->
<!--                  {id: 2, name: $t('tuesday')},-->
<!--                  {id: 3, name: $t('wednesday')},-->
<!--                  {id: 4, name: $t('thursday')},-->
<!--                  {id: 5, name: $t('friday')},-->
<!--                  {id: 6, name: $t('saturday')},-->
<!--                  {id: 7, name: $t('sunday')}]"-->
<!--                item-value="id"-->
<!--                item-text="name"-->
<!--                v-model="settings.guest.dan"-->
<!--                :label="$t('select_day')"-->
<!--                outlined>-->
<!--              </v-select>-->
<!--            </v-col>-->
<!--            <v-col cols="12" sm="3">-->
<!--              <v-row>-->
<!--                <v-col cols="12" sm="7">-->
<!--                  <v-text-field-->
<!--                    :disabled="! settings.guest.rok_sati"-->
<!--                    v-model="settings.guest.broj_sati"-->
<!--                    outlined-->
<!--                    type="number"-->
<!--                    min=0-->
<!--                    max=24-->
<!--                    :label="$t('enter_hours')"-->
<!--                  ></v-text-field>-->
<!--                </v-col>-->
<!--              </v-row>-->
<!--            </v-col>-->
<!--          </v-row>-->
<!--        </v-col>-->
<!--      </v-row>-->
<!--    </v-col>-->
<!--    <v-col style="margin-left: 0;padding-left: 0;padding-right: 40px">-->
<!--      <v-col cols="10" style="margin-top: -10px;margin-bottom: 30px">-->
<!--        <p style="font-weight: 400;font-size: 14px">{{ $t('non_working_days')}}</p>-->
<!--        <v-select-->
<!--          :label="$t('non_working_days')"-->
<!--          v-model="settings.non_working_days"-->
<!--          :items="[-->
<!--            {id: 1, name: $t('monday')},-->
<!--            {id: 2, name: $t('tuesday')},-->
<!--            {id: 3, name: $t('wednesday')},-->
<!--            {id: 4, name: $t('thursday')},-->
<!--            {id: 5, name: $t('friday')},-->
<!--            {id: 6, name: $t('saturday')},-->
<!--            {id: 7, name: $t('sunday')},-->
<!--          ]"-->
<!--          hide-details-->
<!--          multiple-->
<!--          outlined-->
<!--          dense-->
<!--          item-value="id"-->
<!--          item-text="name"-->
<!--      ></v-select>-->
<!--      </v-col>-->
<!--    </v-col>-->

  <v-row class="okolo">
    <v-col lg="4" sm="12">
      <p style="padding-top: 10px" class="font-weight-normal">{{ $t('delivery_time_set') }} <span v-if="settings.delivery_from && settings.delivery_from1" style="padding-top: 22px;font-size: 10px" class="font-weight-bold">({{ settings.delivery_from + ':' + settings.delivery_to }} - {{ settings.delivery_from1 + ':' + settings.delivery_to1 }})</span></p>
    </v-col>
    <v-col lg="4" sm="12">
      <v-row>
        <v-col lg="6" sm="12">
          <v-select
              :items="delivery_from"
              item-value="val"
              v-model="settings.delivery_from_hour"
              :label="$t('starting_from_h')"
              outlined
          >
          </v-select>
        </v-col>
        <v-col lg="6" sm="12">
          <v-select
              :items="delivery_to"
              item-value="val"
              v-model="settings.delivery_from_min"
              :label="$t('starting_from_min')"
              outlined
          >
          </v-select>
        </v-col>
      </v-row>
    </v-col>
    <v-col lg="4" sm="12">
      <v-row>
        <v-col lg="6" sm="12">
          <v-select
              :items="delivery_from"
              item-value="val"
              v-model="settings.delivery_to_hour"
              :label="$t('up_to_h')"
              outlined
          >
          </v-select>
        </v-col>
        <v-col lg="6" sm="12">
          <v-select
              :items="delivery_to"
              item-value="val"
              v-model="settings.delivery_to_min"
              :label="$t('up_to_min')"
              outlined
          >
          </v-select>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
    <v-col cols="12" sm="12">
      <v-checkbox
        :label="translate('Купац може да зада време доставе')"
        type="number"
        outlined
        v-bind:false-value="0"
        v-bind:true-value="1"
        v-model.number="settings.check_delivery_time">
      </v-checkbox>
    </v-col>
  <v-col cols="8" style="display: flex;align-content: center">
      <v-row class="px-3">
        <v-col cols="12" sm="5">
          <p class="mb-0 mt-3">{{ $t('cancel_time') }}</p>
        </v-col>
        <v-col cols="12" sm="2">
          <v-text-field :title="$t('day_before_limit')"
                        :label="$t('up_to_min1')"
                        type="number"
                        outlined
                        v-model.number="settings.guest.guest_cancel_order_time">
          </v-text-field>
        </v-col>
      </v-row>
    </v-col>
  <v-col cols="12" sm="8" class="pl-6">
    <v-row>
      <v-col cols="12" sm="5">
        <p>{{ $t('max_days_to_order_daily_menu') }}</p>
      </v-col>
      <v-col  cols="12" sm="4">
        <v-select
                :items="broj_dana_bez_menija"
                item-value="val"
                v-model="settings.guest.dani_bez_menija"
                :label="$t('selected_days_count')"
                outlined
        ></v-select>
      </v-col>
    </v-row>
  </v-col>
  <!-- VREMENSKA PODESAVANJA GOST-->
  <v-col cols="12" style="font-size:13px;display: flex">
    <v-switch
      v-model="settings.guest_day.new_time_changes"
      inset
    ></v-switch>
    <p style="font-weight: 700;font-size: 19px">{{ $t('new_time_changes') }}</p>
  </v-col>
  <v-col cols="12" v-if="settings.guest_day.new_time_changes">
<!--      <div style="display: flex;justify-content: space-between;font-size: 13px;flex-wrap: wrap">-->
<!--          <div style="width: 20%;"></div>-->
<!--          <div style="width: 20%;text-align: left">{{ $t('time_for_deliver')}}</div>-->
<!--          <div style="width: 20%;padding-left: 34px">{{ $t('first_day_of_order')}}</div>-->
<!--          <div style="width: 20%;text-align: center;padding-right: 35px">{{ $t('limit')}}</div>-->
<!--          <div style="width: 20%;">{{ $t('first_day_of_order1')}}</div>-->
<!--      </div>-->
  </v-col>
  <v-col cols="12" v-if="settings.guest_day.new_time_changes" style="margin-bottom: 30px;">
      <v-row id="vreme-za-narudzbe" class="px-3">
          <v-col style="text-align: center" class="py-0" sm="1" cols="12">
              <label style="display:block;height:100%; margin-right: 5px;background: rgba(251,109,59, 0.10);border-radius: 5px;padding: 14px 3px" for="">{{ $t('monday') }}</label>
          </v-col>
          <v-col style="text-align: center;position: relative;background: #f1f1f1;border-radius: 3px" class="py-0" sm="4" cols="12">
            <div style="text-align: center;font-size: 13px;font-weight: 700;padding-top: 10px">{{ $t('time_for_deliver')}}</div>
            <v-row>
              <v-col lg="6" sm="12">
                <v-row >
                  <v-col lg="6" sm="12">
                      <v-select
                              :items="delivery_from"
                              item-value="val"
                              v-model="settings.guest_day.monday.working_hours.delivery_from"
                              :label="$t('starting_from_h')"
                      >
                      </v-select>
                  </v-col>
                  <v-col lg="6" sm="12">
                        <v-select
                                :items="delivery_to"
                                item-value="val"
                                v-model="settings.guest_day.monday.working_hours.delivery_to"
                                :label="$t('starting_from_min')"
                        >
                        </v-select>
                    </v-col>
                </v-row>
              </v-col>
              <v-col lg="6" sm="12">
                <v-row>
                  <v-col lg="6" sm="12">
                      <v-select
                              :items="delivery_from"
                              item-value="val"
                              v-model="settings.guest_day.monday.working_hours.delivery_from1"
                              :label="$t('up_to_h')"
                      >
                      </v-select>
                  </v-col>
                  <v-col lg="6" sm="12">
                        <v-select
                                :items="delivery_to"
                                item-value="val"
                                v-model="settings.guest_day.monday.working_hours.delivery_to1"
                                :label="$t('up_to_min')"
                        >
                        </v-select>
                    </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
          <v-col style="position: relative" class="py-0" sm="2" cols="12">
            <div style="text-align: center;font-size: 11px;font-weight: 700;padding-bottom: 9px">{{ $t('first_day_of_order')}}</div>
            <v-row style="margin-left: 10px">
                <v-text-field :title="$t('day_before_limit')"
                              :label="$t('day_before_limit')"
                              type="number"
                              v-model.number="settings.guest_day.monday.day_before_limit">
                </v-text-field>
            </v-row>
          </v-col>
          <v-col class="py-0 text-center" style="max-width: 2.3% !important;"><</v-col>
          <v-col style="background: rgba(251,109,59, 0.10);border-radius: 5px;position: relative" class="px-8" sm="2" cols="12">
            <div style="text-align: center;font-size: 12px;font-weight: 700;padding-bottom: 10px">{{ $t('limit')}}</div>
            <v-row>
                  <v-select style="width: 20%"
                            :items="delivery_from_hour"
                            item-value="val"
                            v-model="settings.guest_day.monday.time.time_hour"
                            :label="$t('starting_from_h1')"
                  >
                  </v-select>
                  <v-select style="margin-left: 10px;width: 20%"
                            :items="delivery_to_min"
                            item-value="val"
                            v-model="settings.guest_day.monday.time.time_minut"
                            :label="$t('up_to_min1')"
                  >
                  </v-select>
              </v-row>
          </v-col>
          <v-col class="py-0 text-center" style="max-width: 2.3% !important;">></v-col>
          <v-col style="position: relative" class="py-0" sm="2" cols="12">
            <div style="text-align: center;font-size: 12px;font-weight: 700;padding-bottom: 10px">{{ $t('first_day_of_order1')}}</div>
              <v-row style="margin-left:0">
                <v-text-field :title="$t('day_after_limit')"
                                :label="$t('day_after_limit')"
                                type="number"
                                v-model.number="settings.guest_day.monday.day_after_limit">
                  </v-text-field>
              </v-row>
          </v-col>
      </v-row>
      <v-row class="px-3" style="border-bottom: 1px solid #cfcfcf;display: flex;align-items: center;margin-bottom: 0">
          <v-col style="text-align: center" class="py-0" sm="1" cols="12">
              <label style="display:block;height:100%; margin-right: 5px;background: rgba(251,109,59, 0.10);border-radius: 5px;padding: 14px 3px" for="">{{ $t('tuesday') }}</label>
          </v-col>
          <v-col style="text-align: center;position: relative;background: #f1f1f1;border-radius: 3px" class="py-0" sm="4" cols="12">

              <v-row>
                  <v-col lg="6" sm="12">
                      <v-row>
                          <v-col lg="6" sm="12">
                              <v-select
                                      :items="delivery_from"
                                      item-value="val"
                                      v-model="settings.guest_day.tuesday.working_hours.delivery_from"
                                      :label="$t('starting_from_h')"
                              >
                              </v-select>
                          </v-col>
                          <v-col lg="6" sm="12">
                              <v-select
                                      :items="delivery_to"
                                      item-value="val"
                                      v-model="settings.guest_day.tuesday.working_hours.delivery_to"
                                      :label="$t('starting_from_min')"
                              >
                              </v-select>
                          </v-col>
                      </v-row>
                  </v-col>
                  <v-col lg="6" sm="12">
                      <v-row>
                          <v-col lg="6" sm="12">
                              <v-select
                                      :items="delivery_from"
                                      item-value="val"
                                      v-model="settings.guest_day.tuesday.working_hours.delivery_from1"
                                      :label="$t('up_to_h')"
                              >
                              </v-select>
                          </v-col>
                          <v-col lg="6" sm="12">
                              <v-select
                                      :items="delivery_to"
                                      item-value="val"
                                      v-model="settings.guest_day.tuesday.working_hours.delivery_to1"
                                      :label="$t('up_to_min')"

                              >
                              </v-select>
                          </v-col>
                      </v-row>
                  </v-col>
              </v-row>
          </v-col>
          <v-col class="py-0" sm="2" cols="12">
              <v-row style="margin-left: 10px">
                  <v-text-field :title="$t('day_before_limit')"
                                :label="$t('day_before_limit')"
                                type="number"
                                v-model.number="settings.guest_day.tuesday.day_before_limit">
                  </v-text-field>
              </v-row>
          </v-col>
          <v-col class="py-0 text-center" style="max-width: 2.3% !important;"><</v-col>
          <v-col class="px-8" style="background: rgba(251,109,59, 0.10);border-radius: 5px" sm="2" cols="12">
              <v-row>
                  <v-select style="width: 20%"
                            :items="delivery_from_hour"
                            item-value="val"
                            v-model="settings.guest_day.tuesday.time.time_hour"
                            :label="$t('starting_from_h1')"
                  >
                  </v-select>
                  <v-select style="margin-left: 10px;width: 20%"
                            :items="delivery_to_min"
                            item-value="val"
                            v-model="settings.guest_day.tuesday.time.time_minut"
                            :label="$t('up_to_min1')"
                  >
                  </v-select>
              </v-row>
          </v-col>
          <v-col class="py-0 text-center" style="max-width: 2.3% !important;">> </v-col>
          <v-col class="py-0" sm="2" cols="12">
              <v-row style="margin-left:0">
                  <v-text-field :title="$t('day_after_limit')"
                                :label="$t('day_after_limit')"
                                type="number"
                                v-model.number="settings.guest_day.tuesday.day_after_limit">
                  </v-text-field>
              </v-row>
          </v-col>
      </v-row>
      <v-row class="px-3" style="border-bottom: 1px solid #cfcfcf;display: flex;align-items: center;margin-bottom: 0">
          <v-col class="py-0 text-center" sm="1" cols="12">
              <label style="display:block;height:100%; margin-right: 5px;background: rgba(251,109,59, 0.10);border-radius: 5px;padding: 14px 3px" for="">{{ $t('wednesday') }}</label>
          </v-col>
          <v-col style="text-align: center;position: relative;background: #f1f1f1;border-radius: 3px" class="py-0" sm="4" cols="12">
              <v-row>
                  <v-col lg="6" sm="12">
                      <v-row>
                          <v-col lg="6" sm="12">
                              <v-select
                                      :items="delivery_from"
                                      item-value="val"
                                      v-model="settings.guest_day.wednesday.working_hours.delivery_from"
                                      :label="$t('starting_from_h')"

                              >
                              </v-select>
                          </v-col>
                          <v-col lg="6" sm="12">
                              <v-select
                                      :items="delivery_to"
                                      item-value="val"
                                      v-model="settings.guest_day.wednesday.working_hours.delivery_to"
                                      :label="$t('starting_from_min')"
                              >
                              </v-select>
                          </v-col>
                      </v-row>
                  </v-col>
                  <v-col lg="6" sm="12">
                      <v-row>
                          <v-col lg="6" sm="12">
                              <v-select
                                      :items="delivery_from"
                                      item-value="val"
                                      v-model="settings.guest_day.wednesday.working_hours.delivery_from1"
                                      :label="$t('up_to_h')"
                              >
                              </v-select>
                          </v-col>
                          <v-col lg="6" sm="12">
                              <v-select
                                      :items="delivery_to"
                                      item-value="val"
                                      v-model="settings.guest_day.wednesday.working_hours.delivery_to1"
                                      :label="$t('up_to_min')"

                              >
                              </v-select>
                          </v-col>
                      </v-row>
                  </v-col>
              </v-row>
          </v-col>
          <v-col class="py-0" sm="2" cols="12">
              <v-row style="margin-left: 10px">
                  <v-text-field :title="$t('day_before_limit')"
                                :label="$t('day_before_limit')"
                                type="number"
                                v-model.number="settings.guest_day.wednesday.day_before_limit">
                  </v-text-field>
              </v-row>
          </v-col>
          <v-col class="py-0 text-center" style="max-width: 2.3% !important;"><</v-col>
          <v-col class="px-8" style="background: rgba(251,109,59, 0.10);border-radius: 5px" sm="2" cols="12">
              <v-row>
                  <v-select style="width: 20%"
                            :items="delivery_from_hour"
                            item-value="val"
                            v-model="settings.guest_day.wednesday.time.time_hour"
                            :label="$t('starting_from_h1')"
                  >
                  </v-select>
                  <v-select style="margin-left: 10px;width: 20%"
                            :items="delivery_to_min"
                            item-value="val"
                            v-model="settings.guest_day.wednesday.time.time_minut"
                            :label="$t('up_to_min1')"
                  >
                  </v-select>
              </v-row>
          </v-col>
          <v-col class="py-0 text-center" style="max-width: 2.3% !important;">> </v-col>
          <v-col class="py-0" sm="2" cols="12">
              <v-row style="margin-left:0">
                  <v-text-field :title="$t('day_after_limit')"
                                :label="$t('day_after_limit')"
                                type="number"
                                v-model.number="settings.guest_day.wednesday.day_after_limit">
                  </v-text-field>
              </v-row>
          </v-col>
      </v-row>
      <v-row class="px-3" style="border-bottom: 1px solid #cfcfcf;display: flex;align-items: center;margin-bottom: 0">
          <v-col class="py-0 text-center" sm="1" cols="12">
              <label style="display:block;height:100%; margin-right: 5px;background: rgba(251,109,59, 0.10);border-radius: 5px;padding: 14px 3px" for="">{{ $t('thursday')}}</label>
          </v-col>
          <v-col style="text-align: center;position: relative;background: #f1f1f1;border-radius: 3px" class="py-0" sm="4" cols="12">

              <v-row>
                  <v-col lg="6" sm="12">
                      <v-row>
                          <v-col lg="6" sm="12">
                              <v-select
                                      :items="delivery_from"
                                      item-value="val"
                                      v-model="settings.guest_day.thursday.working_hours.delivery_from"
                                      :label="$t('starting_from_h')"

                              >
                              </v-select>
                          </v-col>
                          <v-col lg="6" sm="12">
                              <v-select
                                      :items="delivery_to"
                                      item-value="val"
                                      v-model="settings.guest_day.thursday.working_hours.delivery_to"
                                      :label="$t('starting_from_min')"
                              >
                              </v-select>
                          </v-col>
                      </v-row>
                  </v-col>
                  <v-col lg="6" sm="12">
                      <v-row>
                          <v-col lg="6" sm="12">
                              <v-select
                                      :items="delivery_from"
                                      item-value="val"
                                      v-model="settings.guest_day.thursday.working_hours.delivery_from1"
                                      :label="$t('up_to_h')"
                              >
                              </v-select>
                          </v-col>
                          <v-col lg="6" sm="12">
                              <v-select
                                      :items="delivery_to"
                                      item-value="val"
                                      v-model="settings.guest_day.thursday.working_hours.delivery_to1"
                                      :label="$t('up_to_min')"

                              >
                              </v-select>
                          </v-col>
                      </v-row>
                  </v-col>
              </v-row>
          </v-col>
          <v-col class="py-0" sm="2" cols="12">
              <v-row style="margin-left: 10px">
                  <v-text-field :title="$t('day_before_limit')"
                                :label="$t('day_before_limit')"
                                type="number"
                                v-model.number="settings.guest_day.thursday.day_before_limit">
                  </v-text-field>
              </v-row>
          </v-col>
          <v-col class="py-0 text-center" style="max-width: 2.3% !important;"><</v-col>
          <v-col class="px-8" style="background: rgba(251,109,59, 0.10);border-radius: 5px" sm="2" cols="12">
              <v-row >
                  <v-select style="width: 20%"
                            :items="delivery_from_hour"
                            item-value="val"
                            v-model="settings.guest_day.thursday.time.time_hour"
                            :label="$t('starting_from_h1')"
                  >
                  </v-select>
                  <v-select style="margin-left: 10px;width: 20%"
                            :items="delivery_to_min"
                            item-value="val"
                            v-model="settings.guest_day.thursday.time.time_minut"
                            :label="$t('up_to_min1')"
                  >
                  </v-select>
              </v-row>
          </v-col>
          <v-col class="py-0 text-center" style="max-width: 2.3% !important;">> </v-col>
          <v-col class="py-0" sm="2" cols="12">
              <v-row style="margin-left:0">
                  <v-text-field :title="$t('day_after_limit')"
                                :label="$t('day_after_limit')"
                                type="number"
                                v-model.number="settings.guest_day.thursday.day_after_limit">
                  </v-text-field>
              </v-row>
          </v-col>
      </v-row>
      <v-row class="px-3" style="border-bottom: 1px solid #cfcfcf;display: flex;align-items: center;margin-bottom: 0">
          <v-col class="py-0 text-center" sm="1" cols="12">
              <label style="display:block;height:100%; margin-right: 5px;background: rgba(251,109,59, 0.10);border-radius: 5px;padding: 14px 3px" for="">{{ $t('friday') }}</label>
          </v-col>
          <v-col style="text-align: center;position:relative;background: #f1f1f1;border-radius: 3px" class="py-0" sm="4" cols="12">
              <v-row>
                  <v-col lg="6" sm="12">
                      <v-row>
                          <v-col lg="6" sm="12">
                              <v-select
                                      :items="delivery_from"
                                      item-value="val"
                                      v-model="settings.guest_day.friday.working_hours.delivery_from"
                                      :label="$t('starting_from_h')"

                              >
                              </v-select>
                          </v-col>
                          <v-col lg="6" sm="12">
                              <v-select
                                      :items="delivery_to"
                                      item-value="val"
                                      v-model="settings.guest_day.friday.working_hours.delivery_to"
                                      :label="$t('starting_from_min')"
                              >
                              </v-select>
                          </v-col>
                      </v-row>
                  </v-col>
                  <v-col lg="6" sm="12">
                      <v-row>
                          <v-col lg="6" sm="12">
                              <v-select
                                      :items="delivery_from"
                                      item-value="val"
                                      v-model="settings.guest_day.friday.working_hours.delivery_from1"
                                      :label="$t('up_to_h')"
                              >
                              </v-select>
                          </v-col>
                          <v-col lg="6" sm="12">
                              <v-select
                                      :items="delivery_to"
                                      item-value="val"
                                      v-model="settings.guest_day.friday.working_hours.delivery_to1"
                                      :label="$t('up_to_min')"

                              >
                              </v-select>
                          </v-col>
                      </v-row>
                  </v-col>
              </v-row>
          </v-col>
          <v-col class="py-0" sm="2" cols="12">
              <v-row style="margin-left: 10px">
                  <v-text-field :title="$t('day_before_limit')"
                                :label="$t('day_before_limit')"
                                type="number"
                                v-model.number="settings.guest_day.friday.day_before_limit">
                  </v-text-field>
              </v-row>
          </v-col>
          <v-col class="py-0 text-center" style="max-width: 2.3% !important;"><</v-col>
          <v-col class="px-8" style="background: rgba(251,109,59, 0.10);border-radius: 5px" sm="2" cols="12">
              <v-row >
                  <v-select style="width: 20%"
                            :items="delivery_from_hour"
                            item-value="val"
                            v-model="settings.guest_day.friday.time.time_hour"
                            :label="$t('starting_from_h1')"
                  >
                  </v-select>
                  <v-select style="margin-left: 10px;width: 20%"
                            :items="delivery_to_min"
                            item-value="val"
                            v-model="settings.guest_day.friday.time.time_minut"
                            :label="$t('up_to_min1')"
                  >
                  </v-select>
              </v-row>
          </v-col>
          <v-col class="py-0 text-center" style="max-width: 2.3% !important;">> </v-col>
          <v-col class="py-0" sm="2" cols="12">
              <v-row>
                  <v-text-field :title="$t('day_after_limit')"
                                :label="$t('day_after_limit')"
                                type="number"
                                v-model.number="settings.guest_day.friday.day_after_limit">
                  </v-text-field>
              </v-row>
          </v-col>
      </v-row>
      <v-row class="px-3" style="border-bottom: 1px solid #cfcfcf;display: flex;align-items: center;margin-bottom: 0">
          <v-col class="py-0 text-center" sm="1" cols="12">
              <label style="display:block;height:100%; margin-right: 5px;background: rgba(251,109,59, 0.10);border-radius: 5px;padding: 14px 3px" for="">{{ $t('saturday')}}</label>
          </v-col>
          <v-col style="text-align: center;position:relative;background: #f1f1f1;border-radius: 3px" class="py-0" sm="4" cols="12">
              <v-row>
                  <v-col lg="6" sm="12">
                      <v-row>
                          <v-col lg="6" sm="12">
                              <v-select
                                      :items="delivery_from"
                                      item-value="val"
                                      v-model="settings.guest_day.saturday.working_hours.delivery_from"
                                      :label="$t('starting_from_h')"

                              >
                              </v-select>
                          </v-col>
                          <v-col lg="6" sm="12">
                              <v-select
                                      :items="delivery_to"
                                      item-value="val"
                                      v-model="settings.guest_day.saturday.working_hours.delivery_to"
                                      :label="$t('starting_from_min')"
                              >
                              </v-select>
                          </v-col>
                      </v-row>
                  </v-col>
                  <v-col lg="6" sm="12">
                      <v-row>
                          <v-col lg="6" sm="12">
                              <v-select
                                      :items="delivery_from"
                                      item-value="val"
                                      v-model="settings.guest_day.saturday.working_hours.delivery_from1"
                                      :label="$t('up_to_h')"
                              >
                              </v-select>
                          </v-col>
                          <v-col lg="6" sm="12">
                              <v-select
                                      :items="delivery_to"
                                      item-value="val"
                                      v-model="settings.guest_day.saturday.working_hours.delivery_to1"
                                      :label="$t('up_to_min')"

                              >
                              </v-select>
                          </v-col>
                      </v-row>
                  </v-col>
              </v-row>
          </v-col>
          <v-col class="py-0" sm="2" cols="12">
              <v-row style="margin-left: 10px">
                  <v-text-field :title="$t('day_before_limit')"
                                :label="$t('day_before_limit')"
                                type="number"
                                v-model.number="settings.guest_day.saturday.day_before_limit">
                  </v-text-field>
              </v-row>
          </v-col>
          <v-col class="py-0 text-center" style="max-width: 2.3% !important;"><</v-col>
          <v-col class="px-8" style="background: rgba(251,109,59, 0.10);border-radius: 5px" sm="2" cols="12">
              <v-row >
                  <v-select style="width: 20%"
                            :items="delivery_from_hour"
                            item-value="val"
                            v-model="settings.guest_day.saturday.time.time_hour"
                            :label="$t('starting_from_h1')"
                  >
                  </v-select>
                  <v-select style="margin-left: 10px;width: 20%"
                            :items="delivery_to_min"
                            item-value="val"
                            v-model="settings.guest_day.saturday.time.time_minut"
                            :label="$t('up_to_min1')"
                  >
                  </v-select>
              </v-row>
          </v-col>
          <v-col class="py-0 text-center" style="max-width: 2.3% !important;">> </v-col>
          <v-col class="py-0" sm="2" cols="12">
              <v-row style="margin-left:0">
                  <v-text-field :title="$t('day_after_limit')"
                                :label="$t('day_after_limit')"
                                type="number"
                                v-model.number="settings.guest_day.saturday.day_after_limit">
                  </v-text-field>
              </v-row>
          </v-col>
      </v-row>
      <v-row class="px-3" style="border-bottom: 1px solid #cfcfcf;display: flex;align-items: center;margin-bottom: 0">
          <v-col class="py-0 text-center" sm="1" cols="12" >
              <label style="display:block;height:100%; margin-right: 5px;background: rgba(251,109,59, 0.10);border-radius: 5px;padding: 14px 3px" for="">{{ $t('sunday')}}</label>
          </v-col>
          <v-col style="text-align: center;position:relative;background: #f1f1f1;border-radius: 3px" class="py-0" sm="4" cols="12">
              <v-row>
                  <v-col lg="6" sm="12">
                      <v-row>
                          <v-col lg="6" sm="12">
                              <v-select
                                      :items="delivery_from"
                                      item-value="val"
                                      v-model="settings.guest_day.sunday.working_hours.delivery_from"
                                      :label="$t('starting_from_h')"

                              >
                              </v-select>
                          </v-col>
                          <v-col lg="6" sm="12">
                              <v-select
                                      :items="delivery_to"
                                      item-value="val"
                                      v-model="settings.guest_day.sunday.working_hours.delivery_to"
                                      :label="$t('starting_from_min')"
                              >
                              </v-select>
                          </v-col>
                      </v-row>
                  </v-col>
                  <v-col lg="6" sm="12">
                      <v-row>
                          <v-col lg="6" sm="12">
                              <v-select
                                      :items="delivery_from"
                                      item-value="val"
                                      v-model="settings.guest_day.sunday.working_hours.delivery_from1"
                                      :label="$t('up_to_h')"
                              >
                              </v-select>
                          </v-col>
                          <v-col lg="6" sm="12">
                              <v-select
                                      :items="delivery_to"
                                      item-value="val"
                                      v-model="settings.guest_day.sunday.working_hours.delivery_to1"
                                      :label="$t('up_to_min')"

                              >
                              </v-select>
                          </v-col>
                      </v-row>
                  </v-col>
              </v-row>
          </v-col>
          <v-col class="py-0" sm="2" cols="12">
              <v-row style="margin-left: 10px">
                  <v-text-field :title="$t('day_before_limit')"
                                :label="$t('day_before_limit')"
                                type="number"
                                v-model.number="settings.guest_day.sunday.day_before_limit">
                  </v-text-field>
              </v-row>
          </v-col>
          <v-col class="py-0 text-center" style="max-width: 2.3% !important;"><</v-col>
          <v-col class="px-8" style="background: rgba(251,109,59, 0.10);border-radius: 5px" sm="2" cols="12">
              <v-row >
                  <v-select style="width: 20%" class="velicina-labele"
                            :items="delivery_from_hour"
                            item-value="val"
                            v-model="settings.guest_day.sunday.time.time_hour"
                            :label="$t('starting_from_h1')"
                  >
                  </v-select>
                  <v-select style="margin-left: 10px;width: 20%" class="velicina-labele"
                            :items="delivery_to_min"
                            item-value="val"
                            v-model="settings.guest_day.sunday.time.time_minut"
                            :label="$t('up_to_min1')"
                  >
                  </v-select>
              </v-row>
          </v-col>
          <v-col class="py-0 text-center" style="max-width: 2.3% !important;">> </v-col>
          <v-col class="py-0" sm="2" cols="12">
              <v-row style="margin-left:0">
                  <v-text-field :title="$t('day_after_limit')"
                                :label="$t('day_after_limit')"
                                type="number"
                                v-model.number="settings.guest_day.sunday.day_after_limit">
                  </v-text-field>
              </v-row>
          </v-col>
      </v-row>
  </v-col>
  <!-- VREMENSKA PODESAVANJA GOST-->
    <v-col cols="12" style="font-size:13px;display: flex">
<!--      Dozvoljeno restoranu da moze da vrsi uklucivanje/iskljucivanje narudzbi-->
      <v-switch
          v-model="settings.can_order_off"
          inset
          :label="$t('restaurant_order_off')"
      ></v-switch>
<!--      Restoran moze/ne moze da prima narudzbe-->
      <v-switch style="margin-left: 40px"
                v-model="settings.receiving_orders"
                inset
                :label="$t('receiving_orders')"
      ></v-switch>
    </v-col>
  </section>
  <v-col lg="12" sm="12" class="mb-0 pb-0">
    <v-col col="12" sm="2" class="">
      <v-select
          :items="valuta"
          item-value="val"
          v-model="settings.price"
          :label="$t('select_currency')"
          outlined
      ></v-select>
    </v-col>
  </v-col>
<!--    KOMENTARI-->
  <v-col cols="12" sm="9" style="padding-right: 70px;margin-bottom: 40px">
    <h3>{{ $t('comments')}}</h3>
    <v-row class="okolo">
    <v-col lg="12" sm="12" class="mb-0 pb-0">
      <v-switch style="margin-bottom: -20px"
        v-model="settings.comments_guest"
        inset
        :label="$t('comments_guest')"
      ></v-switch>
    </v-col>
    <v-col lg="12" sm="12" class="mb-0 pb-0">
      <v-switch style="margin-bottom: -20px"
        v-model="settings.comments_restaurant"
        inset
        :label="$t('comments_restaurant')"
      ></v-switch>
    </v-col>
  </v-row>
  </v-col>
  <!-- KRAJ Narucivanje bez ugovora-->
<!-- JEZICI -->
  <v-col cols="12" sm="9" style="padding-right: 70px">
    <p style="font-weight: 700;font-size: 19px">{{ $t('language_settings')}}</p>
    <v-select
        :label="$t('choose_language')"
        v-model="settings.language"
        :items="[
          {code: 'sr', name: 'Српски - ћирилица'},
          {code: 'sr-lat', name: 'Srpski - Latinica'},
          {code: 'rs', name: 'Ћирилица'},
          {code: 'rs-lat', name: 'Latinica'},
          {code: 'hr', name: 'Hrvatski'},
          {code: 'bs', name: 'Bosanski'},
          {code: 'en', name: 'English'},
          {code: 'mk', name: 'Makedonski'},
          {code: 'si', name: 'Slovenščina'},
        ]"
        hide-details
        multiple
        outlined
        dense
        item-value="code"
        item-text="name"
    ></v-select>
  </v-col>
<!-- IJEKAVICA EKAVICA -->
  <v-col cols="12" sm="10">
    <v-row>
<!--      <v-col cols="12" sm="3">-->
<!--        <v-switch-->
<!--            @click="changeDialekt('ijekavica')"-->
<!--            v-model="settings.ijekavica"-->
<!--            inset-->
<!--            :label="$t('ijekavica')"-->
<!--        ></v-switch>-->
<!--      </v-col>-->
<!--      <v-col cols="12" sm="3">-->
<!--        <v-switch-->
<!--            @click="changeDialekt('ekavica')"-->
<!--            v-model="settings.ekavica"-->
<!--            inset-->
<!--            :label="$t('ekavica')"-->
<!--        ></v-switch>-->
<!--      </v-col>-->
      <v-col cols="12" sm="12" class="mb-0 pb-0 mt-6">
        <p style="font-weight: 700;font-size: 14px">{{ $t('enter_language')}}:</p>
      </v-col>
      <v-col cols="12" sm="3" class="mt-0 pt-0">
        <v-radio-group @change="changeLang()" v-model="alphabet" row>
          <v-radio :label="translate($t('lat'))" value="lat"></v-radio>
          <v-radio label="Ћир" value="cir"></v-radio>
        </v-radio-group>
<!--        <v-checkbox-->
<!--            v-model="lat"-->
<!--            @click="changeLang()"-->
<!--            :label="translate($t('lat'))"-->
<!--        ></v-checkbox>-->
      </v-col>
<!--      <v-col cols="12" sm="2">-->
<!--        <v-checkbox-->
<!--            v-model="cir"-->
<!--            @click="changeLang()"-->
<!--            label="Ћир"-->
<!--        ></v-checkbox>-->
<!--      </v-col>-->
<!--      <v-col cols="12" sm="2">-->
<!--        <v-checkbox-->
<!--            v-model="hrv"-->
<!--            @click="changeLang()"-->
<!--            :label="translate('Хрв')"-->
<!--        ></v-checkbox>-->
<!--      </v-col>-->
    </v-row>
    <v-row>

    </v-row>
  </v-col>
<!--  <v-col cols="12">-->
<!--    <p>{{ $t('choose_language')}}</p>-->
<!--  </v-col>-->

    <v-col cols="12">
      <p style="margin-bottom:0;font-weight: 700;font-size: 28px;text-align: center">{{ $t('design_web')}}</p>
    </v-col>
    <v-col cols="12">
      <p style="margin-bottom:0;font-weight: 700;font-size: 24px">{{ $t('web')}}</p>
    </v-col>
    <v-col sm="12" lg="9">
      <v-row>
        <v-col>
          <v-col class="pb-10 pb-3">
            <v-row
                class="image-width"
                align="center"
                justify="center"
            >
              <v-img
                  :src="
            conf.apiUrlBase +
            '/gallery/' +
            $store.state.restaurant.id +
            '/medium/' +
            settings.logo
          "
                  v-if="settings.logo"
                  contain
              >
                <v-row justify="center" align="center" class="fill-height">
                  <v-btn
                      class="ma-2"
                      color="error"
                      @click="
                changeLogo = 1;
                gallery = true;
              "
                  >
                    {{ translate("Лого") }}
                  </v-btn>
                  <v-btn style="position: absolute;top: 85px;"
                         color="error"
                         @click="settings.logo = null;">
                      <v-icon small>mdi-delete</v-icon>
                  </v-btn>
                </v-row>
              </v-img>
              <v-btn
                  v-else
                  class="ma-2"
                  outlined
                  color="primary"
                  small
                  @click="
            changeLogo = 1;
            gallery = true;
          "
              >
                {{ $t("logo") }}
              </v-btn>
            </v-row>
          </v-col>
        </v-col>
        <v-col>
          <v-col class="pb-10 mb-3">
            <v-row
                class="image-width"
                align="center"
                justify="center"
            >
              <v-img
                  :src="
          conf.apiUrlBase +
          '/gallery/' +
          $store.state.restaurant.id +
          '/medium/' +
          settings.header
        "
                  v-if="settings.header"
                  contain
              >
                <v-row justify="center" align="center" class="fill-height">
                  <v-btn
                      class="ma-2"
                      color="error"
                      @click="
              changeHeader = 1;
              gallery = true;
            "
                  >
                    {{ translate("Header") }}
                  </v-btn>
                  <v-btn style="position: absolute;top: 85px;"
                         color="error"
                         @click="settings.header = null;">
                      <v-icon small>mdi-delete</v-icon>
                  </v-btn>
                </v-row>
              </v-img>
              <v-btn
                  v-else
                  class="ma-2"
                  outlined
                  color="primary"
                  small
                  @click="
          changeHeader = 1;
          gallery = true;
        "
              >
                {{ translate("Header") }}
              </v-btn>
            </v-row>
          </v-col>
        </v-col>
        <v-col>
          <v-col class="pb-10 mb-3">
            <v-row
                align="center"
                class="image-width"
                justify="center"
            >
              <v-img
                  :src="
          conf.apiUrlBase +
          '/gallery/' +
          $store.state.restaurant.id +
          '/medium/' +
          settings.restoran_image
        "
                  v-if="settings.restoran_image"
                  contain
              >
                <v-row justify="center" align="center" class="fill-height">
                  <v-btn
                      class="ma-2"
                      color="error"
                      @click="changeRestoran = 1;gallery = true;">
                    {{ $t("restaurant") }}
                  </v-btn>
                  <v-btn style="position: absolute;top: 85px;"
                         color="error"
                         @click="settings.restoran_image = null;">
                      <v-icon small>mdi-delete</v-icon>
                  </v-btn>
                </v-row>
              </v-img>
              <v-btn
                  v-else
                  class="ma-2"
                  outlined
                  color="primary"
                  small
                  @click="
          changeRestoran = 1;
          gallery = true;
        "
              >
                {{ $t("restaurant") }}
              </v-btn>
            </v-row>
          </v-col>
        </v-col>
      </v-row>
    </v-col>
<!--    <v-row class="okolo" sm="12" lg="6">-->
<!--      <v-col sm="12" lg="4">-->
<!--        <v-text-field-->
<!--            :label="$t('main_colour')"-->
<!--            v-model="settings.primary"-->
<!--        >-->
<!--        </v-text-field>-->
<!--        <v-color-picker-->
<!--            v-model="settings.primary"-->
<!--            hide-canvas-->
<!--            mode="hexa"-->
<!--            swatches-max-height="100"-->
<!--        ></v-color-picker>-->
<!--      </v-col>-->
<!--      <v-col sm="12" lg="4">-->
<!--        <v-text-field-->
<!--            :label="$t('top_bar_color')"-->
<!--            v-model="settings.topbar.background"-->
<!--        >-->
<!--        </v-text-field>-->
<!--        <v-color-picker-->
<!--            v-model="settings.topbar.background"-->
<!--            hide-canvas-->
<!--            mode="hexa"-->
<!--            swatches-max-height="100"-->
<!--        ></v-color-picker>-->
<!--      </v-col>-->
<!--      <v-col sm="12" lg="4">-->
<!--        <v-text-field-->
<!--            :label="$t('footer_color')"-->
<!--            v-model="settings.footer.background"-->
<!--        >-->
<!--        </v-text-field>-->
<!--        <v-color-picker-->
<!--            v-model="settings.footer.background"-->
<!--            hide-canvas-->
<!--            mode="hexa"-->
<!--            swatches-max-height="100"-->
<!--        ></v-color-picker>-->
<!--      </v-col>-->
<!--    </v-row>-->
    <!--  Дизајн мобилне апп-->
    <v-col cols="12">
      <p style="margin-bottom:0;font-weight: 700;font-size: 25px">{{ $t('mobile_app_design')}}</p>
    </v-col>
    <v-row class="pl-4 mb-6">
      <v-col sm="12" lg="3">
        <v-col cols="12" class="pb-4">
            <v-row
                class="image-width"
                align="center"
                justify="center"
            >
              <v-img
                  :src="
              conf.apiUrlBase +
              '/gallery/' +
              $store.state.restaurant.id +
              '/medium/' +
              settings.logo_mob_header
            "
                  v-if="settings.logo_mob_header"
                  contain
              >
                <v-row justify="center" align="center" style="position: relative" class="fill-height">
                  <v-btn
                      class="ma-2"
                      color="error"
                      @click="
                  changeLogoMob = 1;
                  gallery = true;
                "
                  >
                    {{ $t("banner_header") }}
                  </v-btn>
                  <v-btn style="position: absolute;top: 85px;"
                         color="error"
                         @click="
                  settings.logo_mob_header = null;">
                    <v-icon small>mdi-delete</v-icon>
                  </v-btn>
                </v-row>
              </v-img>
              <v-btn
                  v-else
                  class="ma-2"
                  outlined
                  color="primary"
                  small
                  @click="changeLogoMob = 1;gallery = true;"
              >
                {{ $t("banner_header") }}
              </v-btn>
            </v-row>
          </v-col>
        <v-col class="pl-0">
          <v-col cols="10 mt-3" class="pb-0 mb-0">
            <v-text-field
                :label="translate('Линк за header-банер')"
                v-model="settings.mob.baner_link_header"
                class="pt-0"
            >
            </v-text-field>
          </v-col>
        </v-col>
      </v-col>
      <v-col sm="12" lg="3">
        <v-col cols="12" class="pb-4" style="position:relative;">
            <v-row
                class="image-width"
                align="center"
                justify="center"
            >
              <v-img
                  :src="
              conf.apiUrlBase +
              '/gallery/' +
              $store.state.restaurant.id +
              '/medium/' +
              settings.logo_mob_footer
            "
                  v-if="settings.logo_mob_footer"
                  contain
              >
                <v-row justify="center" align="center" class="fill-height">
                  <v-btn
                      class="ma-2"
                      color="error"
                      @click="
                  changeLogoMobFooter = 1;
                  gallery = true;
                "
                  >
                    {{ translate("Банер Footer") }}
                  </v-btn>
                  <v-btn style="position: absolute;top: 73px;"
                         color="error"
                         @click="
                  settings.logo_mob_footer = null;">
                    <v-icon small>mdi-delete</v-icon>
                  </v-btn>
                </v-row>
              </v-img>
              <v-btn
                  v-else
                  class="ma-2"
                  outlined
                  color="primary"
                  small
                  @click="changeLogoMobFooter = 1;gallery = true;"
              >
                {{ $t("banner_footer") }}
              </v-btn>
            </v-row>
          </v-col>
        <v-col class="pl-0">
          <v-col cols="10 mt-3">
            <v-text-field
                :label="translate('Линк за footer-банер')"
                v-model="settings.mob.baner_link_footer"
                class="pt-0"
            >
            </v-text-field>
          </v-col>
        </v-col>
      </v-col>
      <v-col sm="12" lg="3">
        <v-col cols="12" class="pb-4">
            <v-row
                class="image-width"
                align="center"
                justify="center"
            >
              <v-img
                  :src="
              conf.apiUrlBase +
              '/gallery/' +
              $store.state.restaurant.id +
              '/medium/' +
              settings.logo_mob_restoran
            "
                  v-if="settings.logo_mob_restoran"
                  contain
              >
                <v-row justify="center" align="center" class="fill-height">
                  <v-btn
                      class="ma-2"
                      color="error"
                      @click="
                  changeLogoMobFooter = 1;
                  gallery = true;
                "
                  >
                    {{ $t("restaurant") }}
                  </v-btn>
                  <v-btn style="position: absolute;top: 85px;"
                         title="Slika u listi restorana"
                         color="error"
                         @click="settings.logo_mob_restoran = null;">
                    <v-icon small>mdi-delete</v-icon>
                  </v-btn>
                </v-row>
              </v-img>
              <v-btn
                  v-else
                  title="Slika u listi restorana"
                  class="ma-2"
                  outlined
                  color="primary"
                  small
                  @click="changeLogoMobRestoran = 1;gallery = true;"
              >
                {{ $t("restaurant") }}
              </v-btn>
            </v-row>
          </v-col>
          <v-col class="pl-0">
            <v-col cols="10 mt-3">
              <v-text-field
                  :label="translate('Линк за restoran-банер')"
                  v-model="settings.mob.baner_link_restoran"
                  class="pt-0"
              >
              </v-text-field>
            </v-col>
          </v-col>
      </v-col>
      <!-- BOJE MOB -->
      <v-row class="mt-1 okolo">
<!--        <v-col sm="12" lg="3">-->
<!--          <v-text-field-->
<!--              :label="$t('toolbar_colour')"-->
<!--              v-model="settings.mob.primary"-->
<!--          >-->
<!--          </v-text-field>-->
<!--          <v-color-picker-->
<!--              v-model="settings.mobColor.primary"-->
<!--              hide-canvas-->
<!--              mode="hexa"-->
<!--              swatches-max-height="100"-->
<!--          ></v-color-picker>-->
<!--        </v-col>-->
<!--        <v-col sm="12" lg="3">-->
<!--          <v-text-field-->
<!--              :label="$t('toolbar_text_colour')"-->
<!--              v-model="settings.mob.text_toolbar_color"-->
<!--          >-->
<!--          </v-text-field>-->
<!--          <v-color-picker-->
<!--              v-model="settings.mobColor.text_toolbar_color"-->
<!--              hide-canvas-->
<!--              mode="hexa"-->
<!--              swatches-max-height="100"-->
<!--          ></v-color-picker>-->
<!--        </v-col>-->
<!--        <v-col sm="12" lg="3">-->
<!--          <v-text-field-->
<!--              :label="$t('text_color')"-->
<!--              v-model="settings.mob.text_color"-->
<!--          >-->
<!--          </v-text-field>-->
<!--          <v-color-picker-->
<!--              v-model="settings.mobColor.text_color"-->
<!--              hide-canvas-->
<!--              mode="hexa"-->
<!--              swatches-max-height="100"-->
<!--          ></v-color-picker>-->
<!--        </v-col>-->
<!--        <v-col sm="12" lg="3">-->
<!--          <v-text-field-->
<!--              :label="$t('buttons_colour')"-->
<!--              v-model="settings.mob.button_color"-->
<!--          >-->
<!--          </v-text-field>-->
<!--          <v-color-picker-->
<!--              v-model="settings.mobColor.button_color"-->
<!--              hide-canvas-->
<!--              mode="hexa"-->
<!--              swatches-max-height="100"-->
<!--          ></v-color-picker>-->
<!--        </v-col>-->
      </v-row>
    </v-row>
    <v-col cols="12">
      <p style="margin-bottom:0;font-weight: 700;font-size: 25px">{{ $t('links')}}</p>
    </v-col>
    <v-col cols="12">
      <v-row>
        <v-col cols="12" sm="3">
          <v-text-field label="Facebook" v-model="settings.facebook">
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="3">
          <v-text-field label="Instagram" v-model="settings.instagram">
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="3">
          <v-text-field label="Twitter" v-model="settings.twitter">
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="3">
          <v-text-field label="Linkedin" v-model="settings.linkedin">
          </v-text-field>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-row>
        <v-col cols="12" sm="3">
          <v-text-field label="Google Play" v-model="settings.google">
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="3">
          <v-text-field label="Apple Store" v-model="settings.apple">
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="3">
          <v-text-field :label="translate('Huawei store')" v-model="settings.huawei">
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="3">
          <v-text-field :label="translate('Web апликација')" v-model="settings.webapp">
          </v-text-field>
        </v-col>
      </v-row>
    </v-col>

    <v-col cols="10">
      <v-btn @click="save" color="primary" depressed style="width: 100% !important;border-radius: 12px">
        {{$t("record") }}
      </v-btn>
    </v-col>
    <v-dialog v-model="gallery" max-width="80%">
      <v-card>
        <v-card-title class="headline">
          {{ translate("Изаберите слику") }}
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="searchImage"
                @input="filterImage"
                label="Pretraga Slike"
                prepend-inner-icon="mdi-magnify"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              v-for="image in imagesFilter"
              :key="image.id"
              class="d-flex child-flex"
              cols="2"
            >
              <v-row>
                <v-col cols="12" class="pb-0">
                  <v-img
                    :src="
                      conf.apiUrlBase +
                      '/gallery/' +
                      $store.state.restaurant.id +
                      '/medium/' +
                      image.name
                    "
                    @click="selectImage(image)"
                    aspect-ratio="1"
                    class="grey lighten-2"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-col>
                <v-col cols="12" class="pt-0 text-center">
                  <div>{{ image.name | imageName }}</div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="gallery = false">
            {{ translate("Затвори") }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from "../plugins/axios";
import conf from "../config";
import moment from 'moment'

export default {
  data() {
    return {
      conf: conf,
      txt_lang: '',
      changeLogo: 0,
      changeLogoMob: 0,
      changeLogoMobFooter: 0,
      changeLogoMobRestoran: 0,
      changeHeader: 0,
      changeRestoran: 0,
      imagesFilter: '',
      days: [
        {text: 'Понедељак', val: 1},
        {text:'Уторак', val: 2},
        {text:'Среда', val: 3},
        {text:'Четвртак', val: 4},
        {text:'Петак', val: 5},
        {text:'Субота', val: 6},
        {text:'Недеља', val: 7}
      ],
      valuta: [
        {text: 'KM', val: 'KM'},
        {text:'EUR', val: 'EUR'},
        {text:'DIN', val: 'DIN'},
      ],
      broj_dana_bez_menija: [
        {text: '0', val: 0},
        {text: '1', val: 1},
        {text:'2', val: 2},
        {text:'3', val: 3},
        {text:'4', val: 4},
        {text:'5', val: 5},
        {text:'6', val: 6},
        {text:'7', val: 7},
        {text:'8', val: 8},
        {text:'9', val: 9},
        {text:'10', val: 10},
        {text:'11', val: 11},
        {text:'12', val: 12},
        {text:'13', val: 13},
        {text:'14', val: 14},
        {text:'15', val: 15},
      ],
      delivery_from: [
        {text: '00', val: '00'},
        {text: '01', val: '01'},
        {text: '02', val: '02'},
        {text: '03', val: '03'},
        {text: '04', val: '04'},
        {text: '05', val: '05'},
        {text: '06', val: '06'},
        {text: '07', val: '07'},
        {text: '08', val: '08'},
        {text: '09', val: '09'},
        {text: '10', val: '10'},
        {text: '11', val: '11'},
        {text: '12', val: '12'},
        {text: '13', val: '13'},
        {text: '14', val: '14'},
        {text: '15', val: '15'},
        {text: '16', val: '16'},
        {text: '17', val: '17'},
        {text: '18', val: '18'},
        {text: '19', val: '19'},
        {text: '20', val: '20'},
        {text: '21', val: '21'},
        {text: '22', val: '22'},
        {text: '23', val: '23'},
        {text: '24', val: '24'},
      ],
      delivery_to: [
        {text: '00', val: '00'},
        {text: '15', val: '15'},
        {text: '30', val: '30'},
        {text: '45', val: '45'},
      ],
      delivery_from_hour: [
          {text: '00', val: 0},
          {text: '01', val: 1},
          {text: '02', val: 2},
          {text: '03', val: 3},
          {text: '04', val: 4},
          {text: '05', val: 5},
          {text: '06', val: 6},
          {text: '07', val: 7},
          {text: '08', val: 8},
          {text: '09', val: 9},
          {text: '10', val: 10},
          {text: '11', val: 11},
          {text: '12', val: 12},
          {text: '13', val: 13},
          {text: '14', val: 14},
          {text: '15', val: 15},
          {text: '16', val: 16},
          {text: '17', val: 17},
          {text: '18', val: 18},
          {text: '19', val: 19},
          {text: '20', val: 20},
          {text: '21', val: 21},
          {text: '22', val: 22},
          {text: '23', val: 23},
          {text: '24', val: 24},
      ],
      delivery_to_min: [
          {text: '00', val: 0},
          {text: '15', val: 15},
          {text: '30', val: 30},
          {text: '45', val: 45},
          {text: '59', val: 59},
      ],
      settings: {
        comments_guest: false,
        comments_restaurant: false,
        check_delivery_time: 1,
        days: {
          monday: {
            time: {
              time_hour: '',
              time_minut: '',
            },
            day_before_limit: '',
            day_after_limit: '',
            // change_week: ''
          },
          tuesday: {
            time: {
              time_hour: '',
              time_minut: '',
            },
            day_before_limit: '',
            day_after_limit: '',
            // change_week: ''
          },
          wednesday: {
            time: {
              time_hour: '',
              time_minut: '',
            },
            day_before_limit: '',
            day_after_limit: '',
            // change_week: ''
          },
          thursday: {
            time: {
              time_hour: '',
              time_minut: '',
            },
            day_before_limit: '',
            day_after_limit: '',
            // change_week: ''
          },
          friday: {
            time: {
              time_hour: '',
              time_minut: '',
            },
            day_before_limit: '',
            day_after_limit: '',
            // change_week: ''
          },
          saturday: {
            time: {
              time_hour: '',
              time_minut: '',
            },
            day_before_limit: '',
            day_after_limit: '',
            // change_week: ''
          },
          sunday: {
            time: {
              time_hour: '',
              time_minut: '',
            },
            day_before_limit: '',
            day_after_limit: '',
            // change_week: ''
          },
          day: {
            monday: '',
            tuesday: '',
            wednesday: '',
            thursday: '',
            friday: '',
            saturday: '',
            sunday: '',
          }
        },
        google: "https://play.google.com/store/apps/details?id=rs.bitlab.ordera",
        apple: "https://apps.apple.com/app/id1664273786",
        huawei: "https://appgallery.huawei.com/app/C107593211",
        old_time_changes : false,
        new_time_changes : true,
        no_time_limit : false,
        receiving_orders : true,
        can_order_off : false,
        order_before_hours: '',
        language: [],
        non_working_days: [],
        guest_day: {
          new_time_changes : true,
          monday: {
              time: {
                  time_hour: '',
                  time_minut: '',
              },
              day_before_limit: '',
              day_after_limit: '',
              working_hours: {
                  delivery_from: '',
                  delivery_to: '',
                  delivery_from1: '',
                  delivery_to1: '',
              },
              // change_week: ''
          },
          tuesday: {
              time: {
                  time_hour: '',
                  time_minut: '',
              },
              day_before_limit: '',
              day_after_limit: '',
              working_hours: {
                  delivery_from: '',
                  delivery_to: '',
                  delivery_from1: '',
                  delivery_to1: '',
              },
              // change_week: ''
          },
          wednesday: {
              time: {
                  time_hour: '',
                  time_minut: '',
              },
              day_before_limit: '',
              day_after_limit: '',
              working_hours: {
                  delivery_from: '',
                  delivery_to: '',
                  delivery_from1: '',
                  delivery_to1: '',
              },
              // change_week: ''
          },
          thursday: {
              time: {
                  time_hour: '',
                  time_minut: '',
              },
              day_before_limit: '',
              day_after_limit: '',
              working_hours: {
                  delivery_from: '',
                  delivery_to: '',
                  delivery_from1: '',
                  delivery_to1: '',
              },
              // change_week: ''
          },
          friday: {
              time: {
                  time_hour: '',
                  time_minut: '',
              },
              day_before_limit: '',
              day_after_limit: '',
              working_hours: {
                  delivery_from: '',
                  delivery_to: '',
                  delivery_from1: '',
                  delivery_to1: '',
              },
              // change_week: ''
          },
          saturday: {
              time: {
                  time_hour: '',
                  time_minut: '',
              },
              day_before_limit: '',
              day_after_limit: '',
              working_hours: {
                  delivery_from: '',
                  delivery_to: '',
                  delivery_from1: '',
                  delivery_to1: '',
              },
              // change_week: ''
          },
          sunday: {
              time: {
                  time_hour: '',
                  time_minut: '',
              },
              day_before_limit: '',
              day_after_limit: '',
              working_hours: {
                  delivery_from: '',
                  delivery_to: '',
                  delivery_from1: '',
                  delivery_to1: '',
              },
              // change_week: ''
          },
          day: {
              monday: '',
              tuesday: '',
              wednesday: '',
              thursday: '',
              friday: '',
              saturday: '',
              sunday: '',
          }
        },
        guest: {
          guest_cancel_order_time: '',
          post_description: '',
          rok_dana: false,
          meal_in_restaurant: false,
          order_before: '',
          rok_sati: false,
          dan: '',
          broj_sati: '',
          restoran_delivery: false,
          post_delivery: false,
          personal_takeover: false,
          minimal_price: 0,
          post_minimal_price: 0,
          post_price: 0,
          delivery_price: '',
          dani_bez_menija: 1
        },
        lice_bez_ugovora: false,
        topbar: {
          background: '#FB6D3B',
        },
        footer: {
          background: '#FB6D3B',
        },
        mob: {
          primary: '#FB6D3B',
          text_toolbar_color: '#FB6D3B',
          text_color: '#FB6D3B',
          button_color: '#FB6D3B',
          baner_link_header: null,
          baner_link_footer: null,
          baner_link_restoran: null,
        },
        mobColor: {
          primary: '#FB6D3B',
          text_toolbar_color: '#FB6D3B',
          text_color: '#FB6D3B',
          button_color: '#FB6D3B',
        },
        price: null,
        primary: '#FB6D3B',
        logo: null,
        logo_mob_header: null,
        logo_mob_footer: null,
        logo_mob_restoran: null,
        facebook: null,
        instagram: null,
        twitter: null,
        linkedin: null,
        order_before: 0,
        header: null,
        restoran_image: null,
        izvestaj: false,
        ijekavica: false,
        ekavica: false,
        rok_dana:true,
        rok_sati:false,
        broj_sati:'',
        dan: '',
        worker: true,
        can_reserve: false,
        delivery_from_hour: '',
        delivery_from_min: '00',
        delivery_to_hour: '',
        delivery_to_min: '00',
      },
      alphabet: '',
      lang: '',
      lat: '',
      cir: '',
      hrv: '',
      vreme:  moment().format('HH:mm'),

      gallery: false,
      images: [],
      selectedMenu: null,
      searchImage: null,
    };
  },
  watch: {
    'settings.mobColor.primary': {
      handler: function (val, oldVal) {
        this.settings.mob.primary = val ? val.hex : null;
      },
      deep: true,
    },
    'settings.mobColor.text_toolbar_color': {
      handler: function (val, oldVal) {
        this.settings.mob.text_toolbar_color = val ? val.hex : null;
      },
      deep: true,
    },
    'settings.mobColor.text_color': {
      handler: function (val, oldVal) {
        this.settings.mob.text_color = val ? val.hex : null;
      },
      deep: true,
    },
    'settings.mobColor.button_color': {
      handler: function (val, oldVal) {
        this.settings.mob.button_color = val ? val.hex : null;
      },
      deep: true,
    },
  },

  created() {
    // axios.get('/restaurants/' + this.$store.state.restaurant.id + '/get-new-times').then(({data}) => {
    //     console.log(data)
    // })
    if (this.$store.state && this.$store.state.restaurant) {
      this.settings = {
        ...this.settings,
        ...this.$store.state.restaurant.settings,
      };

      if (this.$store.state.restaurant.lang == 'ekavica') {
        this.$set(this.settings, 'ekavica', true)
        this.$set(this.settings, 'ijekavica', false)
      } else
      if (this.$store.state.restaurant.lang == 'ijekavica') {
        this.$set(this.settings, 'ijekavica', true)
        this.$set(this.settings, 'ekavica', false)
      }
      // if (JSON.parse(this.$store.state.restaurant.text_lang)) {
        if (this.$store.state.restaurant.alphabet == 'cir') {
          this.alphabet = 'cir'
          this.cir = this.$store.state.restaurant.alphabet
        }
        if (this.$store.state.restaurant.alphabet == 'lat') {
          this.alphabet = 'lat'
          this.lat = this.$store.state.restaurant.alphabet
        }
      // }
    }

    this.getImage();
  },
  methods: {
    switchTimeStatus(type) {
      if(type == 'old') {
        this.settings.old_time_changes = true
        this.settings.new_time_changes = false
      }
      if(type ==  'new') {
        this.settings.old_time_changes = false
        this.settings.new_time_changes = true
      }
    },
    setDelivery() {

    },
    switchHours() {
      if (this.settings.rok_sati) {
        this.settings.rok_dana = false
      }
      if (! this.settings.rok_sati) {
        this.settings.rok_dana = true
      }
    },
    switchDays() {
      if (this.settings.rok_dana) {
        this.settings.rok_sati = false
      }
      if (! this.settings.rok_dana) {
        this.settings.rok_sati = true
      }
    },
    switchHoursGuest() {
      if (this.settings.guest.rok_sati) {
        this.settings.guest.rok_dana = false
      }
      if (! this.settings.guest.rok_sati) {
        this.settings.guest.rok_dana = true
      }
    },
    switchDaysGuest() {
      if (this.settings.guest.rok_dana) {
        this.settings.guest.rok_sati = false
      }
      if (! this.settings.guest.rok_dana) {
        this.settings.guest.rok_sati = true
      }
    },
    changeDialekt(dialekt) {
      if (dialekt == 'ekavica') {
        this.settings.ekavica = true
        this.settings.ijekavica = false
      }
      if (dialekt == 'ijekavica') {
        this.settings.ekavica = false
        this.settings.ijekavica = true
      }
      let data = {}
      data['restaurants'] = this.$store.state.restaurant.id
      data['jezik'] = dialekt
      axios
        .post("/restaurants/" + this.$store.state.restaurant.id + '/lang', data)
        .then(({res}) => {
          window.location.reload()
        });
    },
    changeLang() {
      let data = {}
      data['user'] = this.$store.state.restaurant.id
      if (this.alphabet == 'cir') {
        this.cir = true
        this.lat = false
      } else if (this.alphabet == 'lat') {
        this.lat = true
        this.cir = false
      }
      data['lat'] = this.lat
      data['cir'] = this.cir
      data['hrv'] = this.hrv
      data['alphabet'] = this.alphabet
      axios.post("/restaurants/" + this.$store.state.restaurant.id + '/text-lang', data)
      .then((res) => {
        this.$root.$emit('langChanged', res)
      });
      this.$forceUpdate()
    },
    filterImage() {
      this.imagesFilter = this.images.filter((i) =>
        i.name.includes(this.searchImage)
      );
    },
    save() {
      if(this.settings.new_time_changes) {
        axios
          .patch("/restaurants/" + this.$store.state.restaurant.id, {
            settings: this.settings,
          })
          .then((res) => {
            location.reload();
          });
      }
      // this.settings.primary = this.$vuetify.theme.themes.light.primary;
      axios
        .patch("/restaurants/" + this.$store.state.restaurant.id, {
          settings: this.settings,
        })
        .then((res) => {
          location.reload();
        });
    },
    getImage() {
      axios.get("/gallery").then((res) => {
        this.images = res.data;
        this.imagesFilter = res.data;
      });
    },
    selectImage(image) {
      this.gallery = false;
      console.log("image", image);
      if (this.changeHeader) {
        this.settings.header = image.name;
        this.changeHeader = 0;
      }
      if (this.changeRestoran) {
          this.settings.restoran_image = image.name;
          this.changeRestoran = 0;
      }
      if (this.changeLogo) {
        this.settings.logo = image.name;
        this.changeLogo = 0;
      }
      if (this.changeLogoMob) {
        this.settings.logo_mob_header = image.name;
        this.changeLogoMob = 0;
      }
      if (this.changeLogoMobFooter) {
        this.settings.logo_mob_footer = image.name;
        this.changeLogoMobFooter = 0;
      }
      if (this.changeLogoMobRestoran) {
          this.settings.logo_mob_restoran = image.name;
          this.changeLogoMobRestoran = 0;
      }
    },
  },
  filters: {
    imageName(name) {
      if (name) {
        let imageName = name.split(".");
        return imageName[0].replace("_", " ").replace("-", " ");
      }
    },
  },
};
</script>
<style scoped>
#slanje-postom {
  margin-top: 0;
  width: 32%;
}
#restoran-obavlja-isporuku {
  width: 32%;
}
#vreme-za-narudzbe {
  border-bottom: 1px solid #cfcfcf;display: flex;align-items: center;margin-bottom: 0;
}
.theme--light.v-image {
  height: 120px !important;
}
.image-width {
  width: 220px;
  height: 120px;
  //background: gray;
  border: 1px solid #cfcfcf;
}
@media(max-width: 767px) {
  #slanje-postom {
    width: 100%;
  }
  #restoran-obavlja-isporuku {
    width: 100%;
  }
  #vreme-za-narudzbe {
    display: block;
  }
  .image-width {
    width: 100%;
  }
  .okolo {
    display: block;
  }
}
</style>
<style>
.velicina-labele .v-label {
    font-size: 13px !important;
}
</style>