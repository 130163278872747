<template>
  <div style="width: 95%;margin: 0 auto">
    <v-toolbar flat>
      <v-toolbar-title>{{ $t("users") + ' - ' + $t('guest') }}</v-toolbar-title>
      <v-divider class="mx-4" inset vertical></v-divider>
      <v-spacer></v-spacer>
      <v-text-field
          class="pr-4 pb-2"
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('search')"
          single-line
          hide-details
          dense
      ></v-text-field>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-data-table
        :search="search"
        :headers="headers"
        :items="guestUsers"
        sort-by="name"
        class="elevation-1 custom-data-table"
        dense
        mobile-breakpoint="0"
    >
      <template v-for="header in headers" v-slot:[`header.${header.value}`]="{ header }">
        {{ $t(header.text) }}
      </template>
      <template v-for="values in headers" v-slot:[`item.${values.value}`]="{ item }">
        {{ translate(item[values.value]) }}
      </template>
      <template v-slot:item.name="{ item, index }">
        {{ item.name + ' ' + item.lastname }}
      </template>
      <template v-slot:item.city_name="{ item, index }">
        {{ item.orders_guest.length ? item.orders_guest[0].location ? item.orders_guest[0].location.city.name : '' : '' }}
      </template>
      <template v-slot:item.state="{ item, index }">
        <span v-if="item.state == 1"><span style="background: #1c7430;border-radius: 30px;padding: 0 10px"></span></span>
        <span v-if="item.state == 0"><span style="background: #ec2222;border-radius: 30px;padding: 0 10px"></span></span>
      </template>
      <template v-slot:item.created_at="{ item, index }">
        {{ formatDateTime(item.created_at) }}
      </template>
      <template v-slot:item.actions="{ item, index }">
        <v-icon class="mr-2" small @click="close2(item,index)" :class="windowSize.x > 1200 ? 'px-2' : 'px-0'">mdi-qrcode-scan</v-icon>
        <a href="">
          <v-icon class="mr-2" style="font-size: 17px" @click.prevent="openDialog(item, index)" color="gray">mdi-pencil</v-icon>
        </a>
        <a href="">
          <v-icon @click.prevent="openDialogDelete(item, index)" style="font-size: 17px" color="primary">mdi-delete</v-icon>
        </a>
      </template>
      <template v-slot:no-data>
        <v-btn class="my-2" :style="'border-radius: 8px;border: 1px solid ' + backGround + ';background: none;color: ' + backGround + ';box-shadow: none;text-transform: capitalize'" @click="getAllGests">
          {{ $t("resets") }}
        </v-btn>
      </template>
    </v-data-table>
    <v-dialog v-model="dialog" max-width="600px" scrollable>
      <template v-slot:activator="{ on, attrs }">
      </template>
      <v-card>
        <v-card-title class="primary" style="display: flex;justify-content: space-between;align-content: center">
          <span style="color: white">{{ editedItem.name + ' ' + editedItem.lastname }}</span>
          <v-btn color="white" class="ml-auto" text @click="close">
            <v-icon class="mr-2">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row class="mt-3">
              <v-col cols="12">
              <v-switch
                  v-model="editedItem.state"
                  inset
                  :label="$t('active')"
                  :false-value="0"
                  :true-value="1"
                  dense
              ></v-switch>
              </v-col>
              <v-spacer></v-spacer>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                      v-model="editedItem.name"
                      :rules="[$t('required_field')]"
                      :label="$t('name')"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                      v-model="editedItem.lastname"
                      :rules="[$t('required_field')]"
                      :label="$t('last_name')"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" :title="translate('Предлог ime.prezime@domen_firme')">
                  <v-text-field
                      v-model="editedItem.email"
                      :rules="[$t('required_field')]"
                      :label="$t('email_or_username')"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                      v-model="editedItem.private_email"
                      :label="$t('private_email')"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                      v-model="editedItem.password"
                      :rules="[$t('required_field')]"
                      :label="$t('password')"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                      v-model="editedItem.address"
                      :label="$t('address')"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                      v-model="editedItem.phone"
                      :label="$t('phone')"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close">
            {{ $t("cancel") }}
          </v-btn>
          <v-btn style="border-radius: 10px" color="primary" dark @click="save">
            {{ $t("edit") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="flex justify-center text-center font-weight-bold" style="color: red">{{$t("delete_confirmation") }} !</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="darken-1" text @click="closeDelete"
          >{{ $t("cancel") }}</v-btn
          >
          <v-btn color="red darken-1" class="font-weight-bold" text @click="deleteItemConfirm"
          >{{ $t("confirm") }}</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog2" max-width="500px" scrollable>
      <v-card>
        <v-card-title class="primary">
          <span class="headline white--text">{{ $t("qr_code") }}</span>
          <v-spacer></v-spacer>
          <v-btn color="white darken-1" small text @click="close2">
            <v-icon large>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <div class="pt-7 pl-1">
            <v-row justify="center">
              <v-col cols="5"> <qrcode-vue id="qrkodstampa" :value="JSON.stringify({
                      id: this.selectedQr,
                      secure: this.selectedQrSecret,
                    })" :size="160" level="H" render-as="svg"/></v-col>
              <v-col cols="7">
                <div style="color:#000; !important;font-weight: 900;margin-left: -15px" class="p-3">
                  <div>ID: {{ editedItem.id }}</div>
                  <div>{{ translate(editedItem.name) + " " +translate(editedItem.lastname) }}</div>
                  <div>{{ editedItem.email }}</div>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "../plugins/axios";
import conf from "../config";
import moment from "moment";
import QrcodeVue from "qrcode.vue";
export default {
  components: {QrcodeVue},
  data() {
    return {
      windowSize: {
        x: 0,
        y: 0,
      },
      selectedQrSecret: '',
      selectedQr: '',
      qrIndex: '',
      dialog2: false,
      search: "",
      editedItem: "",
      editedIndex: "",
      dialog: false,
      dialogDelete: false,
      deleteItem: false,
      itemIndex: '',
      headers: [
        {
          text: "ID",
          align: "start",
          value: "id",
        },
        {
          text: "name",
          align: "start",
          value: "name",
          sortable: true,
        },
        {
          text: "city",
          align: "start",
          value: "city_name",
          sortable: true,
        },
        {
          text: "email",
          align: "start",
          value: "email",
          sortable: true,
        },
        {
          text: "phone",
          align: "start",
          value: "phone",
          sortable: true,
        },
        {
          text: "status",
          align: "start",
          value: "state",
          sortable: true,
        },
        {
          text: "active",
          align: "start",
          value: "created_at",
          sortable: true,
        },
        {
          text: "ordered",
          align: "start",
          value: "orders_guest_count",
          sortable: true,
        },
        {
          text: "actions",
          align: "start",
          value: "actions",
          sortable: true,
        },
      ],
      guestUsers: []
    }
 },
  created() {
    this.getAllGests();
  },
  methods: {
    onResize() {
      this.windowSize = {x: window.innerWidth, y: window.innerHeight}
    },
    close2(item, index) {
      this.editedIndex = this.guestUsers.indexOf(item);
      this.editedItem = Object.assign({}, item);
      axios.get(`/user/${this.editedItem.id}/hash`).then((res) => {
        this.selectedQrSecret = res.data;
        this.selectedQr = this.editedItem.id;
        this.qrIndex = index
        this.dialog2 = !this.dialog2;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    openDialogDelete(item, index) {
      this.dialogDelete = true;
      this.deleteItem = item;
      this.itemIndex = index;
      this.$forceUpdate()
    },
    deleteItemConfirm() {
      axios.post('/user/delete-guest-user',  {user: this.deleteItem, index: this.itemIndex }).then(({data}) => {
        if (data) {
          this.guestUsers.filter((item, index) => {
            if (item.id == this.deleteItem.id) {
              this.guestUsers.splice(index)
            }
          })
          this.getAllGests();
          this.dialogDelete = false;
        }
      })
      this.$forceUpdate()
    },
    save() {
      axios.post('/user/edit-guest-user',  {user: this.editedItem, index: this.itemIndex }).then(({data}) => {
        if (data) {
          this.guestUsers.filter(item => {
            if (item.id == data.data.id) {
              item = data.data
            }
          })
          this.dialog = false;
        }
      })
    },
    close() {
      this.dialog = false;
    },
    openDialog(item) {
      this.dialog = true;
      this.editedItem = item;
    },
    getAllGests() {
      axios.get('/user/get-all-guest-users').then(({data}) => {
        this.guestUsers = data.users
      })
    },
    formatDateTime(date) {
      return moment(date).format('DD.MM.YYYY hh:mm')
    },
  },
  computed: {
    backGround() {
      if(this.$store.state.restaurant) {
        return this.$store.state.restaurant.settings
            ? this.$store.state.restaurant.settings.topbar.background
            : 'primary'
      }
      else if(this.$store.state.company &&  this.$store.state.company.settings &&  this.$store.state.company.settings.topbar) {
        return this.$store.state.company.settings
            ? this.$store.state.company.settings.topbar.background
            : 'primary'
      } else {
        return 'primary'
      }
    }
  }
}

</script>
